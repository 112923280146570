import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AskDaleComponent } from './ask-dale/ask-dale.component';
import { ReactiveFormsModule } from '@angular/forms';
import { InsInvSharedModule } from '@insinv/shared.module';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { VideoCarouselComponent } from './video-carousel/video-carousel.component';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { SeriesListComponent } from './series-list/series-list.component';
import { MatDialogModule } from '@angular/material/dialog';
import { VideoPlayerDialogComponent } from './video-player-dialog/video-player-dialog.component';
import { StoryButtonComponent } from './story-button/story-button.component';
import { RouterModule } from '@angular/router';
import { DalesBookComponent } from './dales-book/dales-book.component';
import { VideoGridSkeletonComponent } from './video-grid-skeleton/video-grid-skeleton.component';
import { InsInvProgressBarModule } from '@insinv/components';

@NgModule({
	declarations: [
		VideoCarouselComponent,
		SeriesListComponent,
		VideoPlayerDialogComponent,
		StoryButtonComponent,
		DalesBookComponent,
		VideoGridSkeletonComponent
	],
	imports: [
		CommonModule,
		RouterModule,
		ReactiveFormsModule,

		MatInputModule,
		MatIconModule,
		MatFormFieldModule,
		MatButtonModule,
		MatSnackBarModule,
		MatProgressSpinnerModule,
		MatDialogModule,
		CarouselModule,

		InsInvSharedModule,
		InsInvProgressBarModule
	],
	exports: [
		VideoCarouselComponent,
		SeriesListComponent,
		VideoPlayerDialogComponent,
		DalesBookComponent,
		VideoGridSkeletonComponent
	],
	providers: [{
		provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
		useValue: {
			appearance: 'outline',
		}
	}],
})
export class ComponentsModule { }
