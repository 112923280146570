import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { InsInvNavigationModule } from '@insinv/components';
import { InsInvSharedModule } from '@insinv/shared.module';

import { NavbarVerticalComponent } from './nav-vertical.component';

@NgModule({
    declarations: [
		NavbarVerticalComponent
    ],
    imports     : [
        MatButtonModule,
        MatIconModule,

        InsInvSharedModule,
        InsInvNavigationModule
    ],
    exports     : [
		NavbarVerticalComponent
    ]
})
export class NavbarVerticalModule
{
}
