import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'video-player-dialog',
	templateUrl: './video-player-dialog.component.html',
	styleUrls: ['./video-player-dialog.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class VideoPlayerDialogComponent implements OnInit {

	public YT: any;
	public videoId: string;
	public player: any;
	public reframed: Boolean = false;

	isRestricted = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

	constructor(
		public matDialogRef: MatDialogRef<VideoPlayerDialogComponent>,
		@Inject(MAT_DIALOG_DATA) _data: any,
	) {
		this.videoId = _data.videoId;
	}

	ngOnInit(): void {
		this.createPlayer();
	}

	createPlayer() {
		// Return if Player is already created
		if (window['YT']) {
			this.startVideo();
			return;
		}

		var tag = document.createElement('script');
		tag.src = 'https://www.youtube.com/iframe_api';
		var firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

		/* 3. startVideo() will create an <iframe> (and YouTube player) after the API code downloads. */
		window['onYouTubeIframeAPIReady'] = () => this.startVideo();
	}

	startVideo() {
		this.reframed = false;
		this.player = new window['YT'].Player('player', {
			videoId: this.videoId,
			width: '100%',
			playerVars: {
				autoplay: 1,
				modestbranding: 1,
				controls: 1,
				disablekb: 1,
				rel: 0,
				showinfo: 0,
				playsinline: 1

			},
			events: {
				'onStateChange': this.onPlayerStateChange.bind(this),
				'onError': this.onPlayerError.bind(this),
				'onReady': this.onPlayerReady.bind(this),
			}
		});
	}

	/* 4. It will be called when the Video Player is ready */
	onPlayerReady(event) {
		if (this.isRestricted) {
			event.target.mute();
			event.target.playVideo();
		} else {
			event.target.playVideo();
		}
	}

	/* 5. API will call this function when Player State changes like PLAYING, PAUSED, ENDED */
	onPlayerStateChange(event) {
		switch (event.data) {
			case window['YT'].PlayerState.PLAYING:
				if (this.cleanTime() == 0) {
					console.log('started ' + this.cleanTime());
				} else {
					console.log('playing ' + this.cleanTime())
				};
				break;
			case window['YT'].PlayerState.PAUSED:
				if (this.player.getDuration() - this.player.getCurrentTime() != 0) {
					console.log('paused' + ' @ ' + this.cleanTime());
				};
				break;
			case window['YT'].PlayerState.ENDED:
				console.log('ended ');
				break;
		};
	};

	cleanTime() {
		return Math.round(this.player.getCurrentTime())
	};

	onPlayerError(event) {
		switch (event.data) {
			case 2:
				console.log('' + this.videoId)
				break;
			case 100:
				break;
			case 101 || 150:
				break;
		};
	};


}
