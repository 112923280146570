<div id="series-list">

	<header class="series-list-header">

		<h2 class="series-list-title">
			{{ series.title }}
		</h2>

	</header>

	<section class="series-list-videos" *ngIf="videos$ | async as videos">

		<div class="skeleton-list" *ngIf="!videos.length; else carousel">
			<div class="skeleton-item" *ngFor="let item of items">
				<div class="skeleton-cover"></div>
				<div class="skeleton-footer">
					<div class="skeleton-title">&nbsp;</div>
					<div class="skeleton-subtitle">&nbsp;</div>
				</div>
			</div>
		</div>

		<ng-template #carousel>
			<owl-carousel-o #carousel [options]="options">
				<ng-container *ngFor="let video of videos">
					<ng-template carouselSlide>
						<div class="slide" style="width: 100%;" (click)="onSlideClicked(video.id)">

							<div class="image-container">
								<img [src]="video.thumbnail" [alt]="video.title" [title]="video.title">
							</div>

							<div class="slide-content">

								<div class="slide-header">
									<h1 class="video-title mb-sm-4">{{ video.title }}</h1>
								</div>

							</div>
						</div>
					</ng-template>
				</ng-container>
			</owl-carousel-o>
		</ng-template>


	</section>

</div>
