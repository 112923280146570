import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ApiService {

	constructor(
		private _http: HttpClient
	) {
	}

	get<T = any>(path: string, params?: HttpParams|any): Observable<T> {
		return this._http.get<T>(
			`${environment.apiUrl}${path}`, { params });
	}

	put(path: string, body: Object = {}): Observable<any> {
		return this._http.put(
			`${environment.apiUrl}${path}`,
			body
		);
	}

	post(path: string, body: Object = {}): Observable<any> {
		return this._http.post(
			`${environment.apiUrl}${path}`,
			body
		);
	}

	delete(path: string, params?: HttpParams | {}): Observable<any> {
		return this._http.delete(
			`${environment.apiUrl}${path}`, { params }
		);
	}

	uploadFile(formData: FormData): Observable<any> {
		return this._http.post(
			`${environment.apiUrl}/forms/insideinvestigations/file_upload`,
			formData,
			{
				responseType: 'text'
			}
		);
	}

	request(request: HttpRequest<any>): Observable<any> {
		return this._http.request(request);
	}
}
