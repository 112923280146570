import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Video } from 'app/models/video.model';
import { Observable } from 'rxjs';
import { Playlist } from 'app/models/playlist.model';

export interface ResourceResponse<T> {
	data: T;
}

@Injectable({
	providedIn: 'root'
})
export class VideoService {

	constructor(
		private _apiService: ApiService
	) { }

	getLatestVideos(playlistId?: string, maxResults = 20): Observable<ResourceResponse<Video[]>> {
		const params = { maxResults };

		if (playlistId) {
			params['playlistId'] = playlistId;
		}
		return this._apiService.get('/videos', params);
	}

	getPlaylists(): Observable<ResourceResponse<Playlist[]>> {
		return this._apiService.get('/videos/playlists');
	}

	getPlaylistVideos(playlistId: string): Observable<ResourceResponse<Video[]>> {
		return this.getLatestVideos(playlistId, 20);
	}
}
