import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';
import { SafePipe } from './pipes/safe.pipe';

@NgModule({
	declarations: [SafePipe],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,

		FlexLayoutModule,
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,

		FlexLayoutModule,
		SafePipe
	]
})
export class InsInvSharedModule {
}
