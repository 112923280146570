<div id="main">

	<div class="header">

		<button mat-icon-button class="navbar-toggle-button" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
			<mat-icon class="secondary-text">menu</mat-icon>
		</button>

		<a class="brand-image" href="/" title="Inside Investigations" fxFlex="0 1 140px" fxFlex.gt-sm="1 1 240px">
			<picture>
				<source media="(min-width:650px)" srcset="/assets/logo-alpha.png">
				<source media="(min-width:465px)" srcset="/assets/logo-alpha@0.5x.png">
				<img src="/assets/logo-alpha@0.25x.png" alt="Inside Investigations" title="Inside Investigations">
			</picture>
		</a>

		<!-- NAVBAR: Top -->
		<ng-container>
			<ng-container *ngTemplateOutlet="topNavbar"></ng-container>
		</ng-container>
		<!-- / NAVBAR: Top -->


	</div>

	<div id="container-1" class="container">

		<!-- NAVBAR: Left -->
		<ng-container>
			<ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
		</ng-container>
		<!-- / NAVBAR: Left -->

		<div id="container-2" class="container">

			<div id="container-3" class="container" #scrollContainer>

				<content></content>

				<footer></footer>

			</div>

		</div>

	</div>


</div>

<!-- TOP NAVBAR -->
<ng-template #topNavbar>
	<navbar class="top-navbar" variant="horizontal" fxHide fxShow.gt-md>
	</navbar>
</ng-template>
<!-- / TOP NAVBAR -->

<!-- LEFT NAVBAR -->
<ng-template #leftNavbar>
	<insinv-sidebar name="navbar">
		<navbar class="left-navbar" variant="vertical"></navbar>
	</insinv-sidebar>
</ng-template>
<!-- / LEFT NAVBAR -->
