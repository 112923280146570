import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ApiService } from 'app/services/api.service';
import { insinvAnimations } from '@insinv/animations';
import { MatButton } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'contact-us',
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [{
		provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
		useValue: {
			appearance: 'outline',
			hideRequiredMarker: false
		}
	}],
	animations: insinvAnimations
})
export class ContactUsComponent implements OnInit {

	form: FormGroup;
	files: File[] = [];
	submitting: boolean = false;
	submitted: boolean = false;

	@ViewChild('uploadBtn') uploadBtn: MatButton;

	constructor(
		private _fb: FormBuilder,
		private _apiService: ApiService,
		private _matSnackbar: MatSnackBar
	) { }

	ngOnInit(): void {

		this.form = this._fb.group({
			first_name: ['', Validators.required],
			last_name: ['', Validators.required],
			email: ['', Validators.email],
			phone: ['', Validators.required],
			address_1: ['', Validators.required],
			address_2: [''],
			city: ['', Validators.required],
			state: ['', Validators.required],
			zip: ['', Validators.required],
			date_began: ['', Validators.required],
			company_name: [''],
			amount_lost: [null],
			story: ['', Validators.required]
		})
	}

	uploadFile(file: any): Promise<File> {

		return new Promise( (resolve, reject) => {
			const formData = new FormData();
			formData.append('file', file);
			this._apiService.uploadFile(formData).subscribe(
				(tmpPath: string) => {
					this.form.enable();
					file.serverPath = tmpPath;
					resolve(file);
				}, reject);

		});
	}

	onFilesChanged(event): any {
		const files = event.target.files;

		if(!files.length) return;

		if(this.files.length > 5) {
			return alert('Cannot add more than 5 documents');
		}

		if(files.length + this.files.length > 5) {
			return alert('The amount of documents you have selected exceeds the maximum amount of documents you can upload (5)');
		}

		this.uploadBtn.disabled = true;

		[...files].forEach( (file: File) => {
			this.uploadFile(file).then(
				(response: File) => this.files.push(response)
			).finally( () => this.uploadBtn.disabled = false);
		})
	}

	submit(): void {
		if(this.form.invalid) return;
		this.submitting = true;
		const files = this.files.map( (file: any) => {
			return {
				serverPath: file.serverPath,
				lastModified: file.lastModified,
				name: file.name,
				size: file.size,
				type: file.type
			}
		})
		const formData = {
			files,
			...this.form.value
		}
		this.form.disable();
		this._apiService.post('/forms/insideinvestigations/contact', formData).subscribe(
			response => {
				this.showMessage(['green', 'insinv-white-fg'], 'Form Saved! Please check your email for confirmation of our receipt of your information.');
				this.submitting = false;
				this.submitted = true;
			},
			error => {
				this.form.enable();
				this.submitting = false;
				this.showMessage(['warn'], error.message);
			}
		)
	}

	showMessage(classlist = ['primary'], message: string): void {
		this._matSnackbar.open(message, null, {
			panelClass: [...classlist, 'text-center'],
			duration: 5000
		});
	}

	get submitText(): string {
		return this.submitting
			? 'Sending...'
			: (this.submitted ? 'Form Sent!' : 'Submit Form');
	}

}
