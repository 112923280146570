import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { LayoutService } from '../layout.service';
import { Subject } from 'rxjs';

@Component({
	selector: 'footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class FooterComponent {

	@ViewChild('footerRef') footerRef: ElementRef;

	constructor(
		private _layoutService: LayoutService
	) { }

	onFooterVpChange(event): void {
		this._layoutService.onFooterVpChange$.next([event, this.footerRef])
	}

}
