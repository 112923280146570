<div class="content-container">
	<div id="watch-on-tv" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

		<header class="page-header">
			<h1 class="page-title">Watch On TV</h1>
		</header>

		<div class="text-center px-20 mb-40">
			If you would like to add Inside Investigations to your station's programming, <a href="https://telcoproductions.com/inside-investigations-2" target="_blank">click here.</a>
		</div>

		<mat-table [dataSource]="dataSource" class="mat-elevation-z8">

			<!-- Market Column -->
			<ng-container matColumnDef="city">
				<mat-header-cell *matHeaderCellDef> City </mat-header-cell>
				<mat-cell *matCellDef="let element"> {{element.CITY}} </mat-cell>
			</ng-container>

			<!-- Stations Column -->
			<ng-container matColumnDef="station">
				<mat-header-cell *matHeaderCellDef> Station </mat-header-cell>
				<mat-cell *matCellDef="let element"> {{element.STATION}} </mat-cell>
			</ng-container>

			<!-- Stations Column -->
			<ng-container matColumnDef="time">
				<mat-header-cell *matHeaderCellDef> Time </mat-header-cell>
				<mat-cell *matCellDef="let element"> {{element.TIME}} </mat-cell>
			</ng-container>

			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
		</mat-table>

	</div>
</div>
