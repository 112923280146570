import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';

@Component({
	selector: 'story-button',
	templateUrl: './story-button.component.html',
	styleUrls: ['./story-button.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class StoryButtonComponent implements OnInit {

	element: ElementRef;

	constructor(
		element: ElementRef
	) {
		this.element = element;
	}

	ngOnInit(): void {
	}

}
