import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import marketList from './market-list';
import { orderBy } from 'lodash';
import { insinvAnimations } from '@insinv/animations';

export interface MarketItem {
	"CITY": string;
	"STATION": string;
	"TIME": string;
}

@Component({
	selector: 'app-watch-on-tv',
	templateUrl: './watch-on-tv.component.html',
	styleUrls: ['./watch-on-tv.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: insinvAnimations
})
export class WatchOnTvComponent implements OnInit {

	dataSource: MarketItem[];

	displayedColumns = ["city", "station", "time"];

	constructor() {
		const data = orderBy(marketList, ['CITY', 'STATION', 'TIME'], ['asc', 'asc', 'asc']);

		this.dataSource = data;
	}

	ngOnInit(): void {
		//
	}

}
