import { Component, OnInit, ViewEncapsulation, ViewChild, ViewChildren, QueryList, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { VideoService } from 'app/services/video.service';
import { finalize, tap } from 'rxjs/operators';
import { OwlOptions, CarouselComponent } from 'ngx-owl-carousel-o';

import { insinvAnimations } from '@insinv/animations';
import { Video } from 'app/models/video.model';

@Component({
	selector: 'video-carousel',
	templateUrl: './video-carousel.component.html',
	styleUrls: ['./video-carousel.component.scss'],
	animations: insinvAnimations,
	encapsulation: ViewEncapsulation.None
})
export class VideoCarouselComponent implements OnInit {

	@ViewChild('carousel', {static: true}) carousel: CarouselComponent;
	@ViewChildren('slideRef') slides: QueryList<ElementRef>;

	@Input('animate') animate: boolean = false;

	@Output('slideClicked') slideClicked: EventEmitter<any>;

	videos: Video[] = [];
	loading: boolean;

	options: OwlOptions = {
		autoplay: true,
		autoWidth: true,
		loop: true,
		dots: false,
		autoplayTimeout: 7000,
		mouseDrag: false,
		smartSpeed: 2000,
		nav: false,
		skip_validateItems: true,
		responsive: {
			0: {
				items: 1
			},
			420: {
				items: 1
			}
		},
	}

	constructor(
		private _videoService: VideoService
	) {
		this.slideClicked = new EventEmitter();
	}

	ngOnInit(): void {
		// this.videos = sampleVideos.map( v => new YoutubeSearchResult(v));
		this.fetchVideos();
	}

	onCarouselTranslated(event) {

	}

	onCarouselChange($event) {

	}

	onSlideClicked(videoId: string): void {
		this.slideClicked.emit(videoId);
	}

	fetchVideos() {
		this.loading = true;
		this._videoService.getLatestVideos(null, 5).pipe(
			tap(response => this.videos = response.data),
			finalize(() => this.loading = false)
		).subscribe();
	}

	get activeSlide(): ElementRef {
		return this.slides.find( s => s.nativeElement.parentElement.classList.contains('active') );
	}

}
