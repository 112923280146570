import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentComponent } from './content/content.component';
import { InsInvSharedModule } from '@insinv/shared.module';
import { RouterModule } from '@angular/router';
import { InsInvNavigationModule, InsInvSidebarModule } from '@insinv/components';
import { LayoutComponent } from './layout.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { NavbarModule } from './navbar/navbar.module';
import { FooterComponent } from './footer/footer.component';
import { InViewportModule } from '@thisissoon/angular-inviewport';
import { LayoutService } from './layout.service';



@NgModule({
	declarations: [
		ContentComponent,
		LayoutComponent,
		FooterComponent
	],
	imports: [
		CommonModule,
		RouterModule,

		InViewportModule,
		MatSidenavModule,
		MatIconModule,

		InsInvSidebarModule,
		InsInvSharedModule,
		InsInvNavigationModule,

		NavbarModule
	],
	exports: [
		ContentComponent,
		LayoutComponent
	],
	providers: [
		LayoutService
	]
})
export class LayoutModule { }
