import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, RouterEvent, NavigationEnd, NavigationStart } from '@angular/router';
import { InvestigationsComponent } from './pages/investigations/investigations.component';
import { Error404Component } from './pages/404/error-404.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { BehindTheScenesComponent } from './pages/behind-the-scenes/behind-the-scenes.component';
import { WatchOnTvComponent } from './pages/watch-on-tv/watch-on-tv.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { filter } from 'rxjs/operators';
import { MassTortComponent } from './pages/mass-tort/mass-tort.component';

const routes: Routes = [
	{
		path: 'investigations',
		loadChildren: () => import('./pages/investigations/investigations.module').then(m => m.InvestigationsModule)
	},
	// {
	// 	path: 'behind-the-scenes',
	// 	component: BehindTheScenesComponent,
	// 	data: { title: 'Behind The Scenes - Inside Investigations' }
	// },
	{
		path: 'contact-us',
		component: ContactUsComponent,
		data: { title: 'Contact Us - Inside Investigations' }
	},
	{
		path: 'watch-on-tv',
		component: WatchOnTvComponent,
		data: { title: 'Watch On TV - Inside Investigations' }
	},
	{
		path: 'about-dale',
		loadChildren: () => import('./pages/about-dale/about-dale.module').then(m => m.AboutDaleModule),
		data: { title: 'About Dale - Inside Investigations' }
	},
	{
		path: 'privacy-policy',
		component: PrivacyPolicyComponent,
		data: { title: 'Privacy Policy - Inside Investigations' }
	},
	{
		path: 'mass-tort',
		component: MassTortComponent,
		data: { title: 'Mass Tort - Inside Investigations' }
	},
	{
		path: '404',
		component: Error404Component,
		data: { title: '404 Not Found - Inside Investigations' }
	},
	{
		path: '',
		redirectTo: 'investigations',
		pathMatch: 'full'
	},
	{
		path: '**',
		redirectTo: '404'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		scrollPositionRestoration: 'disabled',
		enableTracing: false
	})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
