import { MarketItem } from './watch-on-tv.component';

const markets: MarketItem[] = [
	{
		 "CITY": "Albany-Schenectady-Troy",
		 "STATION": "WFNYCD",
		 "TIME": "Sat 05:00:PM"
	},
	{
		 "CITY": "Albany-Schenectady-Troy",
		 "STATION": "WFNYCA",
		 "TIME": "Sat 02:30:PM"
	},
	{
		 "CITY": "Albany-Schenectady-Troy",
		 "STATION": "WFNYCD7",
		 "TIME": "Sat 10:00:AM"
	},
	{
		 "CITY": "Albany-Schenectady-Troy",
		 "STATION": "WFNYCD",
		 "TIME": "Sat 10:00:AM"
	},
	{
		 "CITY": "Albany-Schenectady-Troy",
		 "STATION": "WFNYCA",
		 "TIME": "Sat 05:00:AM"
	},
	{
		 "CITY": "Albany-Schenectady-Troy",
		 "STATION": "WNYADT",
		 "TIME": "Sat 05:00:AM"
	},
	{
		 "CITY": "Albany-Schenectady-Troy",
		 "STATION": "WNYA",
		 "TIME": "Fri 07:30:PM"
	},
	{
		 "CITY": "Albany-Schenectady-Troy",
		 "STATION": "WFNYCD7",
		 "TIME": "Thu 07:30:PM"
	},
	{
		 "CITY": "Albany-Schenectady-Troy",
		 "STATION": "WFNYCD7",
		 "TIME": "Wed 07:30:PM"
	},
	{
		 "CITY": "Albany-Schenectady-Troy",
		 "STATION": "WFNYCD7",
		 "TIME": "Tue 07:30:PM"
	},
	{
		 "CITY": "Albany-Schenectady-Troy",
		 "STATION": "WFNYCD7",
		 "TIME": "Mon 07:30:PM"
	},
	{
		 "CITY": "Albany-Schenectady-Troy",
		 "STATION": "WFNYCD7",
		 "TIME": "Sun 05:00:PM"
	},
	{
		 "CITY": "Albany-Schenectady-Troy",
		 "STATION": "WFNYCD",
		 "TIME": "Sun 05:00:PM"
	},
	{
		 "CITY": "Albany-Schenectady-Troy",
		 "STATION": "WFNYCA",
		 "TIME": "Sun 02:30:PM"
	},
	{
		 "CITY": "Albany-Schenectady-Troy",
		 "STATION": "WFNYCD7",
		 "TIME": "Sun 01:00:PM"
	},
	{
		 "CITY": "Albany-Schenectady-Troy",
		 "STATION": "WFNYCD5",
		 "TIME": "Sat 02:30:PM"
	},
	{
		 "CITY": "Albuquerque-Santa Fe",
		 "STATION": "KYNMCD",
		 "TIME": "Sun 04:30:AM"
	},
	{
		 "CITY": "Albuquerque-Santa Fe",
		 "STATION": "K31KBD",
		 "TIME": "Sun 04:00:AM"
	},
	{
		 "CITY": "Albuquerque-Santa Fe",
		 "STATION": "K19JZD",
		 "TIME": "Sun 04:00:AM"
	},
	{
		 "CITY": "Albuquerque-Santa Fe",
		 "STATION": "K17JJD6",
		 "TIME": "Sun 04:00:AM"
	},
	{
		 "CITY": "Albuquerque-Santa Fe",
		 "STATION": "K45DHD",
		 "TIME": "Sun 04:00:AM"
	},
	{
		 "CITY": "Albuquerque-Santa Fe",
		 "STATION": "K48MHD",
		 "TIME": "Sun 04:00:AM"
	},
	{
		 "CITY": "Albuquerque-Santa Fe",
		 "STATION": "K34CRD",
		 "TIME": "Sun 04:00:AM"
	},
	{
		 "CITY": "Albuquerque-Santa Fe",
		 "STATION": "K20GUD",
		 "TIME": "Sun 04:00:AM"
	},
	{
		 "CITY": "Albuquerque-Santa Fe",
		 "STATION": "K36LFD",
		 "TIME": "Sun 04:00:AM"
	},
	{
		 "CITY": "Albuquerque-Santa Fe",
		 "STATION": "K10CGD",
		 "TIME": "Sun 04:00:AM"
	},
	{
		 "CITY": "Albuquerque-Santa Fe",
		 "STATION": "K51DSD",
		 "TIME": "Sun 04:00:AM"
	},
	{
		 "CITY": "Albuquerque-Santa Fe",
		 "STATION": "K65AS",
		 "TIME": "Sun 04:00:AM"
	},
	{
		 "CITY": "Albuquerque-Santa Fe",
		 "STATION": "KOVTDT",
		 "TIME": "Sun 04:00:AM"
	},
	{
		 "CITY": "Albuquerque-Santa Fe",
		 "STATION": "KOATDT",
		 "TIME": "Sun 04:00:AM"
	},
	{
		 "CITY": "Albuquerque-Santa Fe",
		 "STATION": "KOVT",
		 "TIME": "Sun 04:00:AM"
	},
	{
		 "CITY": "Albuquerque-Santa Fe",
		 "STATION": "KOCT",
		 "TIME": "Sun 04:00:AM"
	},
	{
		 "CITY": "Albuquerque-Santa Fe",
		 "STATION": "KOAT",
		 "TIME": "Sat 05:00:PM"
	},
	{
		 "CITY": "Atlanta",
		 "STATION": "WGTADT5",
		 "TIME": "Sat 01:00:PM"
	},
	{
		 "CITY": "Atlanta",
		 "STATION": "WLVOD10",
		 "TIME": "Sat 01:00:PM"
	},
	{
		 "CITY": "Atlanta",
		 "STATION": "WLVOLD2",
		 "TIME": "Sat 01:00:PM"
	},
	{
		 "CITY": "Atlanta",
		 "STATION": "WATCDT2",
		 "TIME": "Fri 07:30:PM"
	},
	{
		 "CITY": "Atlanta",
		 "STATION": "WGTADT5",
		 "TIME": "Thu 07:30:PM"
	},
	{
		 "CITY": "Atlanta",
		 "STATION": "WGTADT5",
		 "TIME": "Wed 07:30:PM"
	},
	{
		 "CITY": "Atlanta",
		 "STATION": "WGTADT5",
		 "TIME": "Tue 07:30:PM"
	},
	{
		 "CITY": "Atlanta",
		 "STATION": "WGTADT5",
		 "TIME": "Mon 07:30:PM"
	},
	{
		 "CITY": "Atlanta",
		 "STATION": "WGTADT5",
		 "TIME": "Mon 08:00:AM"
	},
	{
		 "CITY": "Atlanta",
		 "STATION": "WTBSLD5",
		 "TIME": "Sun 02:30:PM"
	},
	{
		 "CITY": "Atlanta",
		 "STATION": "WGTADT5",
		 "TIME": "Sun 01:00:PM"
	},
	{
		 "CITY": "Atlanta",
		 "STATION": "WANND1",
		 "TIME": "Sun 01:00:PM"
	},
	{
		 "CITY": "Atlanta",
		 "STATION": "WANNLP",
		 "TIME": "Sat 05:30:PM"
	},
	{
		 "CITY": "Austin",
		 "STATION": "KADFLD",
		 "TIME": "Sat 09:00:AM"
	},
	{
		 "CITY": "Austin",
		 "STATION": "KADFLD",
		 "TIME": "Sun 04:00:PM"
	},
	{
		 "CITY": "Austin",
		 "STATION": "KADFLD",
		 "TIME": "Sun 09:00:AM"
	},
	{
		 "CITY": "Austin",
		 "STATION": "KADFLD",
		 "TIME": "Wed 08:30:PM"
	},
	{
		 "CITY": "Birmingham (Ann and Tusc)",
		 "STATION": "WSWHLD4",
		 "TIME": "Mon 03:30:AM"
	},
	{
		 "CITY": "Boise",
		 "STATION": "KRIDLD8",
		 "TIME": "Sat 08:00:AM"
	},
	{
		 "CITY": "Boise",
		 "STATION": "KRIDLD8",
		 "TIME": "Sun 03:00:PM"
	},
	{
		 "CITY": "Boise",
		 "STATION": "KRIDLD8",
		 "TIME": "Sun 08:00:AM"
	},
	{
		 "CITY": "Boise",
		 "STATION": "KRIDLD8",
		 "TIME": "Sun 01:00:AM"
	},
	{
		 "CITY": "Boston (Manchester)",
		 "STATION": "WFXZCD",
		 "TIME": "Sun 01:00:PM"
	},
	{
		 "CITY": "Boston (Manchester)",
		 "STATION": "WFXZCA",
		 "TIME": "Sat 02:00:PM"
	},
	{
		 "CITY": "Bowling Green",
		 "STATION": "WDNZLD",
		 "TIME": "Sun 02:00:PM"
	},
	{
		 "CITY": "Buffalo",
		 "STATION": "WBXZD10",
		 "TIME": "Sat 10:00:AM"
	},
	{
		 "CITY": "Buffalo",
		 "STATION": "WBXZD10",
		 "TIME": "Sun 05:00:PM"
	},
	{
		 "CITY": "Buffalo",
		 "STATION": "WBXZD10",
		 "TIME": "Sun 10:00:AM"
	},
	{
		 "CITY": "Buffalo",
		 "STATION": "WBXZD10",
		 "TIME": "Sat 05:30:PM"
	},
	{
		 "CITY": "Burlington-Plattsburgh",
		 "STATION": "WYCI",
		 "TIME": "Sun 06:30:PM"
	},
	{
		 "CITY": "Burlington-Plattsburgh",
		 "STATION": "WYCIDT",
		 "TIME": "Sun 06:00:PM"
	},
	{
		 "CITY": "Burlington-Plattsburgh",
		 "STATION": "WYCI",
		 "TIME": "Sun 06:00:PM"
	},
	{
		 "CITY": "Burlington-Plattsburgh",
		 "STATION": "WYCIDT",
		 "TIME": "Sat 05:00:AM"
	},
	{
		 "CITY": "Charleston, SC",
		 "STATION": "WHDCD10",
		 "TIME": "Sun 04:30:AM"
	},
	{
		 "CITY": "Charleston-Huntington",
		 "STATION": "W07BY",
		 "TIME": "Thu 07:30:PM"
	},
	{
		 "CITY": "Charleston-Huntington",
		 "STATION": "W07BY",
		 "TIME": "Wed 07:30:PM"
	},
	{
		 "CITY": "Charleston-Huntington",
		 "STATION": "W07BY",
		 "TIME": "Tue 07:30:PM"
	},
	{
		 "CITY": "Charleston-Huntington",
		 "STATION": "W07BY",
		 "TIME": "Sun 02:30:PM"
	},
	{
		 "CITY": "Charleston-Huntington",
		 "STATION": "W07BY",
		 "TIME": "Sat 01:00:PM"
	},
	{
		 "CITY": "Chico-Redding",
		 "STATION": "KGECLD3",
		 "TIME": "Tue 07:00:PM"
	},
	{
		 "CITY": "Chico-Redding",
		 "STATION": "KGECLD3",
		 "TIME": "Mon 08:00:AM"
	},
	{
		 "CITY": "Chico-Redding",
		 "STATION": "KGECLD2",
		 "TIME": "Mon 07:00:AM"
	},
	{
		 "CITY": "Cleveland-Akron (Canton)",
		 "STATION": "WBNXDT",
		 "TIME": "Sun 12:30:PM"
	},
	{
		 "CITY": "Cleveland-Akron (Canton)",
		 "STATION": "WBNX",
		 "TIME": "Sat 11:30:AM"
	},
	{
		 "CITY": "Colorado Springs-Pueblo",
		 "STATION": "KSPKLP",
		 "TIME": "Fri 05:30:PM"
	},
	{
		 "CITY": "Colorado Springs-Pueblo",
		 "STATION": "KSPKLP",
		 "TIME": "Thu 05:30:PM"
	},
	{
		 "CITY": "Colorado Springs-Pueblo",
		 "STATION": "KSPKLP",
		 "TIME": "Wed 05:30:PM"
	},
	{
		 "CITY": "Colorado Springs-Pueblo",
		 "STATION": "KSPKLP",
		 "TIME": "Tue 05:30:PM"
	},
	{
		 "CITY": "Colorado Springs-Pueblo",
		 "STATION": "KSPKLP",
		 "TIME": "Mon 05:30:PM"
	},
	{
		 "CITY": "Colorado Springs-Pueblo",
		 "STATION": "KSPKLP",
		 "TIME": "Sun 12:30:PM"
	},
	{
		 "CITY": "Colorado Springs-Pueblo",
		 "STATION": "KSPKLP",
		 "TIME": "Mon 05:00:AM"
	},
	{
		 "CITY": "Columbus, GA (Opelika, AL)",
		 "STATION": "WQMKDT",
		 "TIME": "Fri 06:30:PM"
	},
	{
		 "CITY": "Columbus, GA (Opelika, AL)",
		 "STATION": "WQMKDT",
		 "TIME": "Thu 06:30:PM"
	},
	{
		 "CITY": "Columbus, GA (Opelika, AL)",
		 "STATION": "WQMKDT",
		 "TIME": "Wed 06:30:PM"
	},
	{
		 "CITY": "Columbus, GA (Opelika, AL)",
		 "STATION": "WQMKDT",
		 "TIME": "Tue 06:30:PM"
	},
	{
		 "CITY": "Columbus, GA (Opelika, AL)",
		 "STATION": "WQMKDT",
		 "TIME": "Mon 06:30:PM"
	},
	{
		 "CITY": "Columbus, GA (Opelika, AL)",
		 "STATION": "WQMKDT",
		 "TIME": "Sun 01:30:PM"
	},
	{
		 "CITY": "Columbus, GA (Opelika, AL)",
		 "STATION": "WQMKDT",
		 "TIME": "Sat 11:30:AM"
	},
	{
		 "CITY": "Corpus Christi",
		 "STATION": "K22JAD2",
		 "TIME": "Sun 12:00:PM"
	},
	{
		 "CITY": "Corpus Christi",
		 "STATION": "K47DF",
		 "TIME": "Sun 05:30:AM"
	},
	{
		 "CITY": "Corpus Christi",
		 "STATION": "KRISDT",
		 "TIME": "Sun 05:30:AM"
	},
	{
		 "CITY": "Corpus Christi",
		 "STATION": "KRIS",
		 "TIME": "Sat 02:30:PM"
	},
	{
		 "CITY": "Dallas-Ft. Worth",
		 "STATION": "KLEGCD6",
		 "TIME": "Sat 09:00:AM"
	},
	{
		 "CITY": "Dallas-Ft. Worth",
		 "STATION": "KLEGCD6",
		 "TIME": "Sun 04:00:PM"
	},
	{
		 "CITY": "Dallas-Ft. Worth",
		 "STATION": "KLEGCD6",
		 "TIME": "Sun 12:00:PM"
	},
	{
		 "CITY": "Dallas-Ft. Worth",
		 "STATION": "KLEGCD3",
		 "TIME": "Sun 12:00:PM"
	},
	{
		 "CITY": "Dallas-Ft. Worth",
		 "STATION": "KFWDDT2",
		 "TIME": "Sun 09:00:AM"
	},
	{
		 "CITY": "Dallas-Ft. Worth",
		 "STATION": "KLEGCD6",
		 "TIME": "Sat 02:30:PM"
	},
	{
		 "CITY": "Davenport-R.Island-Moline",
		 "STATION": "WQADDT3",
		 "TIME": "Sun 09:00:PM"
	},
	{
		 "CITY": "Davenport-R.Island-Moline",
		 "STATION": "WQADDT3",
		 "TIME": "Sun 10:00:AM"
	},
	{
		 "CITY": "Dayton",
		 "STATION": "WRCXLP",
		 "TIME": "Sun 02:30:PM"
	},
	{
		 "CITY": "Dayton",
		 "STATION": "WRCXLP",
		 "TIME": "Sun 12:00:PM"
	},
	{
		 "CITY": "Denver",
		 "STATION": "KZDNLD8",
		 "TIME": "Sat 08:00:AM"
	},
	{
		 "CITY": "Denver",
		 "STATION": "KZDNLD8",
		 "TIME": "Sun 03:00:PM"
	},
	{
		 "CITY": "Denver",
		 "STATION": "KZDNLD8",
		 "TIME": "Sun 08:00:AM"
	},
	{
		 "CITY": "Denver",
		 "STATION": "KZDNLD8",
		 "TIME": "Sun 12:30:PM"
	},
	{
		 "CITY": "Des Moines-Ames",
		 "STATION": "WBXFCD",
		 "TIME": "Sat 04:30:PM"
	},
	{
		 "CITY": "Detroit",
		 "STATION": "WHNED11",
		 "TIME": "Sat 10:00:AM"
	},
	{
		 "CITY": "Detroit",
		 "STATION": "WHNED11",
		 "TIME": "Sun 05:00:PM"
	},
	{
		 "CITY": "Detroit",
		 "STATION": "WHNED11",
		 "TIME": "Sun 01:30:PM"
	},
	{
		 "CITY": "Detroit",
		 "STATION": "WADLDT",
		 "TIME": "Sun 01:30:PM"
	},
	{
		 "CITY": "Detroit",
		 "STATION": "WADL",
		 "TIME": "Sun 10:00:AM"
	},
	{
		 "CITY": "Detroit",
		 "STATION": "WHNED11",
		 "TIME": "Sat 03:00:PM"
	},
	{
		 "CITY": "El Paso (Las Cruces)",
		 "STATION": "KVIASTA",
		 "TIME": "Sun 04:30:AM"
	},
	{
		 "CITY": "El Paso (Las Cruces)",
		 "STATION": "K57BV",
		 "TIME": "Sun 04:30:AM"
	},
	{
		 "CITY": "El Paso (Las Cruces)",
		 "STATION": "KVIADT",
		 "TIME": "Sun 04:30:AM"
	},
	{
		 "CITY": "El Paso (Las Cruces)",
		 "STATION": "KVIA",
		 "TIME": "Sun 06:30:PM"
	},
	{
		 "CITY": "Elmira (Corning)",
		 "STATION": "WYDCDT2",
		 "TIME": "Sun 09:00:AM"
	},
	{
		 "CITY": "Elmira (Corning)",
		 "STATION": "WJKPLP",
		 "TIME": "Sun 08:00:AM"
	},
	{
		 "CITY": "Elmira (Corning)",
		 "STATION": "W26BF",
		 "TIME": "Sun 08:00:AM"
	},
	{
		 "CITY": "Elmira (Corning)",
		 "STATION": "WYDCDT",
		 "TIME": "Sun 08:00:AM"
	},
	{
		 "CITY": "Elmira (Corning)",
		 "STATION": "WYDC",
		 "TIME": "Sun 12:00:PM"
	},
	{
		 "CITY": "Evansville",
		 "STATION": "WYYWCD2",
		 "TIME": "Tue 09:00:PM"
	},
	{
		 "CITY": "Evansville",
		 "STATION": "WYYWCD2",
		 "TIME": "Mon 03:00:AM"
	},
	{
		 "CITY": "Fresno-Visalia",
		 "STATION": "KGOFLD2",
		 "TIME": "Sat 02:00:PM"
	},
	{
		 "CITY": "Fresno-Visalia",
		 "STATION": "KVHFLD6",
		 "TIME": "Sat 11:30:AM"
	},
	{
		 "CITY": "Fresno-Visalia",
		 "STATION": "KHSCLD9",
		 "TIME": "Sat 11:30:AM"
	},
	{
		 "CITY": "Fresno-Visalia",
		 "STATION": "KBIDLD5",
		 "TIME": "Sat 07:00:AM"
	},
	{
		 "CITY": "Fresno-Visalia",
		 "STATION": "KVHFLD6",
		 "TIME": "Fri 04:30:PM"
	},
	{
		 "CITY": "Fresno-Visalia",
		 "STATION": "KHSCLD9",
		 "TIME": "Fri 04:30:PM"
	},
	{
		 "CITY": "Fresno-Visalia",
		 "STATION": "KBIDLD5",
		 "TIME": "Thu 04:30:PM"
	},
	{
		 "CITY": "Fresno-Visalia",
		 "STATION": "KHSCLD9",
		 "TIME": "Thu 04:30:PM"
	},
	{
		 "CITY": "Fresno-Visalia",
		 "STATION": "KBIDLD5",
		 "TIME": "Wed 04:30:PM"
	},
	{
		 "CITY": "Fresno-Visalia",
		 "STATION": "KHSCLD9",
		 "TIME": "Wed 04:30:PM"
	},
	{
		 "CITY": "Fresno-Visalia",
		 "STATION": "KBIDLD5",
		 "TIME": "Tue 10:00:PM"
	},
	{
		 "CITY": "Fresno-Visalia",
		 "STATION": "KGOFLD2",
		 "TIME": "Tue 04:30:PM"
	},
	{
		 "CITY": "Fresno-Visalia",
		 "STATION": "KHSCLD9",
		 "TIME": "Tue 04:30:PM"
	},
	{
		 "CITY": "Fresno-Visalia",
		 "STATION": "KBIDLD5",
		 "TIME": "Mon 04:30:PM"
	},
	{
		 "CITY": "Fresno-Visalia",
		 "STATION": "KHSCLD9",
		 "TIME": "Mon 04:30:PM"
	},
	{
		 "CITY": "Fresno-Visalia",
		 "STATION": "KBIDLD5",
		 "TIME": "Sun 02:00:PM"
	},
	{
		 "CITY": "Fresno-Visalia",
		 "STATION": "KVHFLD6",
		 "TIME": "Sun 11:30:AM"
	},
	{
		 "CITY": "Fresno-Visalia",
		 "STATION": "KHSCLD9",
		 "TIME": "Sun 11:30:AM"
	},
	{
		 "CITY": "Fresno-Visalia",
		 "STATION": "KBIDLD5",
		 "TIME": "Sun 07:00:AM"
	},
	{
		 "CITY": "Fresno-Visalia",
		 "STATION": "KVHFLD6",
		 "TIME": "Sat 05:00:PM"
	},
	{
		 "CITY": "Ft. Wayne",
		 "STATION": "WFFTDT",
		 "TIME": "Mon 03:00:AM"
	},
	{
		 "CITY": "Ft. Wayne",
		 "STATION": "WFFT",
		 "TIME": "Mon 08:00:AM"
	},
	{
		 "CITY": "Glendive",
		 "STATION": "K11IAD4",
		 "TIME": "Sat 12:30:PM"
	},
	{
		 "CITY": "Glendive",
		 "STATION": "K26NSD4",
		 "TIME": "Fri 05:30:PM"
	},
	{
		 "CITY": "Glendive",
		 "STATION": "K11IAD4",
		 "TIME": "Fri 05:30:PM"
	},
	{
		 "CITY": "Glendive",
		 "STATION": "K26NSD4",
		 "TIME": "Thu 05:30:PM"
	},
	{
		 "CITY": "Glendive",
		 "STATION": "K11IAD4",
		 "TIME": "Thu 05:30:PM"
	},
	{
		 "CITY": "Glendive",
		 "STATION": "K26NSD4",
		 "TIME": "Wed 05:30:PM"
	},
	{
		 "CITY": "Glendive",
		 "STATION": "K11IAD4",
		 "TIME": "Wed 05:30:PM"
	},
	{
		 "CITY": "Glendive",
		 "STATION": "K26NSD4",
		 "TIME": "Tue 05:30:PM"
	},
	{
		 "CITY": "Glendive",
		 "STATION": "K11IAD4",
		 "TIME": "Tue 05:30:PM"
	},
	{
		 "CITY": "Glendive",
		 "STATION": "K26NSD4",
		 "TIME": "Mon 05:30:PM"
	},
	{
		 "CITY": "Glendive",
		 "STATION": "K11IAD4",
		 "TIME": "Mon 05:30:PM"
	},
	{
		 "CITY": "Glendive",
		 "STATION": "K26NSD4",
		 "TIME": "Sun 12:30:PM"
	},
	{
		 "CITY": "Glendive",
		 "STATION": "K11IAD4",
		 "TIME": "Sun 12:30:PM"
	},
	{
		 "CITY": "Glendive",
		 "STATION": "K26NSD4",
		 "TIME": "Sun 04:21:PM"
	},
	{
		 "CITY": "Great Falls",
		 "STATION": "K16AZD4",
		 "TIME": "Fri 05:30:PM"
	},
	{
		 "CITY": "Great Falls",
		 "STATION": "K16AZD4",
		 "TIME": "Thu 05:30:PM"
	},
	{
		 "CITY": "Great Falls",
		 "STATION": "K16AZD4",
		 "TIME": "Wed 05:30:PM"
	},
	{
		 "CITY": "Great Falls",
		 "STATION": "K16AZD4",
		 "TIME": "Tue 05:30:PM"
	},
	{
		 "CITY": "Great Falls",
		 "STATION": "K16AZD4",
		 "TIME": "Mon 05:30:PM"
	},
	{
		 "CITY": "Great Falls",
		 "STATION": "K16AZD4",
		 "TIME": "Sun 12:30:PM"
	},
	{
		 "CITY": "Great Falls",
		 "STATION": "K16AZD4",
		 "TIME": "Sun 05:30:AM"
	},
	{
		 "CITY": "Greenvll-Spart-Ashevll-And",
		 "STATION": "WASVLD5",
		 "TIME": "Sat 05:30:PM"
	},
	{
		 "CITY": "Greenvll-Spart-Ashevll-And",
		 "STATION": "WNGSLD5",
		 "TIME": "Tue 10:00:PM"
	},
	{
		 "CITY": "Greenvll-Spart-Ashevll-And",
		 "STATION": "WASVLD5",
		 "TIME": "Tue 10:00:PM"
	},
	{
		 "CITY": "Greenvll-Spart-Ashevll-And",
		 "STATION": "WNGSLD5",
		 "TIME": "Mon 08:00:AM"
	},
	{
		 "CITY": "Greenvll-Spart-Ashevll-And",
		 "STATION": "WASVLD4",
		 "TIME": "Mon 08:00:AM"
	},
	{
		 "CITY": "Greenvll-Spart-Ashevll-And",
		 "STATION": "WNGSLD4",
		 "TIME": "Sat 04:00:PM"
	},
	{
		 "CITY": "Harlingen-Wslco-Brnsvl-McA",
		 "STATION": "KRGVDT3",
		 "TIME": "Sun 11:00:PM"
	},
	{
		 "CITY": "Harlingen-Wslco-Brnsvl-McA",
		 "STATION": "KRGVDT3",
		 "TIME": "Sat 02:30:PM"
	},
	{
		 "CITY": "Houston",
		 "STATION": "KBPXLD3",
		 "TIME": "Fri 02:00:PM"
	},
	{
		 "CITY": "Houston",
		 "STATION": "KBPXLD3",
		 "TIME": "Thu 11:00:PM"
	},
	{
		 "CITY": "Houston",
		 "STATION": "KBPXLD3",
		 "TIME": "Thu 10:30:AM"
	},
	{
		 "CITY": "Houston",
		 "STATION": "KBPXLD3",
		 "TIME": "Mon 07:00:PM"
	},
	{
		 "CITY": "Houston",
		 "STATION": "KBPXLD3",
		 "TIME": "Sun 07:00:PM"
	},
	{
		 "CITY": "Houston",
		 "STATION": "KBPXLD3",
		 "TIME": "Sun 01:00:PM"
	},
	{
		 "CITY": "Houston",
		 "STATION": "KBPXLD3",
		 "TIME": "Sun 01:00:PM"
	},
	{
		 "CITY": "Jackson, MS",
		 "STATION": "WJTVDT2",
		 "TIME": "Sun 03:05:AM"
	},
	{
		 "CITY": "Jackson, MS",
		 "STATION": "WJTVDT",
		 "TIME": "Sun 03:05:AM"
	},
	{
		 "CITY": "Jackson, MS",
		 "STATION": "WJTV",
		 "TIME": "Sat 12:00:PM"
	},
	{
		 "CITY": "La Crosse-Eau Claire",
		 "STATION": "KQEGCD",
		 "TIME": "Fri 06:30:PM"
	},
	{
		 "CITY": "La Crosse-Eau Claire",
		 "STATION": "KQEGCD",
		 "TIME": "Wed 06:30:PM"
	},
	{
		 "CITY": "La Crosse-Eau Claire",
		 "STATION": "KQEGCD",
		 "TIME": "Tue 06:30:PM"
	},
	{
		 "CITY": "La Crosse-Eau Claire",
		 "STATION": "KQEGCD",
		 "TIME": "Mon 06:30:PM"
	},
	{
		 "CITY": "La Crosse-Eau Claire",
		 "STATION": "KQEGCD",
		 "TIME": "Sun 11:30:AM"
	},
	{
		 "CITY": "La Crosse-Eau Claire",
		 "STATION": "KQEGCD",
		 "TIME": "Sun 12:00:PM"
	},
	{
		 "CITY": "Las Vegas",
		 "STATION": "KPVMLD",
		 "TIME": "Wed 08:30:PM"
	},
	{
		 "CITY": "Las Vegas",
		 "STATION": "KPVMLP",
		 "TIME": "Sat 06:30:PM"
	},
	{
		 "CITY": "Lexington",
		 "STATION": "WBONHD",
		 "TIME": "Sat 02:30:PM"
	},
	{
		 "CITY": "Lexington",
		 "STATION": "WBONLD",
		 "TIME": "Sat 02:30:PM"
	},
	{
		 "CITY": "Lexington",
		 "STATION": "W10BM",
		 "TIME": "Fri 07:30:PM"
	},
	{
		 "CITY": "Lexington",
		 "STATION": "WBONHD",
		 "TIME": "Fri 07:30:PM"
	},
	{
		 "CITY": "Lexington",
		 "STATION": "WBONLD",
		 "TIME": "Thu 07:30:PM"
	},
	{
		 "CITY": "Lexington",
		 "STATION": "WBONHD",
		 "TIME": "Thu 07:30:PM"
	},
	{
		 "CITY": "Lexington",
		 "STATION": "WBONLD",
		 "TIME": "Thu 07:30:PM"
	},
	{
		 "CITY": "Lexington",
		 "STATION": "W10BM",
		 "TIME": "Wed 07:30:PM"
	},
	{
		 "CITY": "Lexington",
		 "STATION": "WBONHD",
		 "TIME": "Wed 07:30:PM"
	},
	{
		 "CITY": "Lexington",
		 "STATION": "WBONLD",
		 "TIME": "Wed 07:30:PM"
	},
	{
		 "CITY": "Lexington",
		 "STATION": "W10BM",
		 "TIME": "Tue 07:30:PM"
	},
	{
		 "CITY": "Lexington",
		 "STATION": "WBONHD",
		 "TIME": "Tue 07:30:PM"
	},
	{
		 "CITY": "Lexington",
		 "STATION": "WBONLD",
		 "TIME": "Tue 07:30:PM"
	},
	{
		 "CITY": "Lexington",
		 "STATION": "W10BM",
		 "TIME": "Mon 07:30:PM"
	},
	{
		 "CITY": "Lexington",
		 "STATION": "WBONHD",
		 "TIME": "Mon 07:30:PM"
	},
	{
		 "CITY": "Lexington",
		 "STATION": "WBONLD",
		 "TIME": "Sun 02:30:PM"
	},
	{
		 "CITY": "Lexington",
		 "STATION": "WBONHD",
		 "TIME": "Sun 02:30:PM"
	},
	{
		 "CITY": "Lexington",
		 "STATION": "WBONLD",
		 "TIME": "Sun 02:30:PM"
	},
	{
		 "CITY": "Lexington",
		 "STATION": "W10BM",
		 "TIME": "Sat 02:30:PM"
	},
	{
		 "CITY": "Lima",
		 "STATION": "WOIWLD",
		 "TIME": "Sat 11:30:PM"
	},
	{
		 "CITY": "Lima",
		 "STATION": "WTLWDT",
		 "TIME": "Sat 11:30:PM"
	},
	{
		 "CITY": "Lima",
		 "STATION": "WTLW",
		 "TIME": "Sat 12:30:PM"
	},
	{
		 "CITY": "Los Angeles",
		 "STATION": "KAZADT4",
		 "TIME": "Sun 10:00:AM"
	},
	{
		 "CITY": "Los Angeles",
		 "STATION": "KFLALD4",
		 "TIME": "Sat 05:30PM"
	},
	{
		 "CITY": "Marquette",
		 "STATION": "WBUPDT",
		 "TIME": "Sun 02:00:PM"
	},
	{
		 "CITY": "Marquette",
		 "STATION": "WBUP",
		 "TIME": "Sat 03:30:PM"
	},
	{
		 "CITY": "Minneapolis-St. Paul",
		 "STATION": "K30OCD",
		 "TIME": "Fri 07:30:PM"
	},
	{
		 "CITY": "Minneapolis-St. Paul",
		 "STATION": "K21GND",
		 "TIME": "Fri 04:30:PM"
	},
	{
		 "CITY": "Minneapolis-St. Paul",
		 "STATION": "K30OCD",
		 "TIME": "Thu 04:30:PM"
	},
	{
		 "CITY": "Minneapolis-St. Paul",
		 "STATION": "K30OCD",
		 "TIME": "Wed 09:00:PM"
	},
	{
		 "CITY": "Minneapolis-St. Paul",
		 "STATION": "K21GND",
		 "TIME": "Wed 04:30:PM"
	},
	{
		 "CITY": "Minneapolis-St. Paul",
		 "STATION": "K30OCD",
		 "TIME": "Tue 04:30:PM"
	},
	{
		 "CITY": "Minneapolis-St. Paul",
		 "STATION": "K30OCD",
		 "TIME": "Mon 04:30:PM"
	},
	{
		 "CITY": "Minneapolis-St. Paul",
		 "STATION": "K30OCD",
		 "TIME": "Sun 11:30:AM"
	},
	{
		 "CITY": "Minneapolis-St. Paul",
		 "STATION": "K30OCD",
		 "TIME": "Sat 05:00:PM"
	},
	{
		 "CITY": "Monroe-El Dorado",
		 "STATION": "KCIBLD3",
		 "TIME": "Sat 04:30:PM"
	},
	{
		 "CITY": "Monroe-El Dorado",
		 "STATION": "KCIBLD",
		 "TIME": "Sat 04:30:PM"
	},
	{
		 "CITY": "Monroe-El Dorado",
		 "STATION": "KCIBLP",
		 "TIME": "Tue 10:00:PM"
	},
	{
		 "CITY": "Monroe-El Dorado",
		 "STATION": "KCIBLD3",
		 "TIME": "Sun 02:30:PM"
	},
	{
		 "CITY": "Myrtle Beach-Florence",
		 "STATION": "WTNGCD4",
		 "TIME": "Sat 03:00:PM"
	},
	{
		 "CITY": "Nashville",
		 "STATION": "WLLCLD3",
		 "TIME": "Sat 06:30:AM"
	},
	{
		 "CITY": "Nashville",
		 "STATION": "WTVFD12",
		 "TIME": "Sat 06:30:AM"
	},
	{
		 "CITY": "Nashville",
		 "STATION": "WTVFSTA2",
		 "TIME": "Sat 06:30:AM"
	},
	{
		 "CITY": "Nashville",
		 "STATION": "WTVFDT2",
		 "TIME": "Sat 02:37:AM"
	},
	{
		 "CITY": "Nashville",
		 "STATION": "WTVFD11",
		 "TIME": "Sat 02:37:AM"
	},
	{
		 "CITY": "Nashville",
		 "STATION": "WTVFSTA",
		 "TIME": "Sat 02:37:AM"
	},
	{
		 "CITY": "Nashville",
		 "STATION": "WTVFDT",
		 "TIME": "Sat 02:37:AM"
	},
	{
		 "CITY": "Nashville",
		 "STATION": "WTVF",
		 "TIME": "Fri 06:30:PM"
	},
	{
		 "CITY": "Nashville",
		 "STATION": "WLLCLD3",
		 "TIME": "Thu 06:30:PM"
	},
	{
		 "CITY": "Nashville",
		 "STATION": "WLLCLD3",
		 "TIME": "Wed 06:30:PM"
	},
	{
		 "CITY": "Nashville",
		 "STATION": "WLLCLD3",
		 "TIME": "Tue 06:30:PM"
	},
	{
		 "CITY": "Nashville",
		 "STATION": "WLLCLD3",
		 "TIME": "Mon 06:30:PM"
	},
	{
		 "CITY": "Nashville",
		 "STATION": "WLLCLD3",
		 "TIME": "Sun 01:30:PM"
	},
	{
		 "CITY": "Nashville",
		 "STATION": "WLLCLD3",
		 "TIME": "Sat 04:30:PM"
	},
	{
		 "CITY": "Norfolk-Portsmth-Newpt Nws",
		 "STATION": "WGNTDT",
		 "TIME": "Mon 03:30:AM"
	},
	{
		 "CITY": "Norfolk-Portsmth-Newpt Nws",
		 "STATION": "WGNT",
		 "TIME": "Mon 12:41:AM"
	},
	{
		 "CITY": "Norfolk-Portsmth-Newpt Nws",
		 "STATION": "W18EGD3",
		 "TIME": "Mon 12:41:AM"
	},
	{
		 "CITY": "Norfolk-Portsmth-Newpt Nws",
		 "STATION": "WTKRDT",
		 "TIME": "Mon 12:41:AM"
	},
	{
		 "CITY": "Norfolk-Portsmth-Newpt Nws",
		 "STATION": "WTKR",
		 "TIME": "Sun 01:00:PM"
	},
	{
		 "CITY": "Norfolk-Portsmth-Newpt Nws",
		 "STATION": "W45CLD2",
		 "TIME": "Mon 09:00:AM"
	},
	{
		 "CITY": "Oklahoma City",
		 "STATION": "K27JOD",
		 "TIME": "Sat 06:30:PM"
	},
	{
		 "CITY": "Oklahoma City",
		 "STATION": "K15HQD",
		 "TIME": "Sat 06:30:PM"
	},
	{
		 "CITY": "Oklahoma City",
		 "STATION": "K42ICD",
		 "TIME": "Sat 06:30:PM"
	},
	{
		 "CITY": "Oklahoma City",
		 "STATION": "K31JWD",
		 "TIME": "Sat 06:30:PM"
	},
	{
		 "CITY": "Oklahoma City",
		 "STATION": "K25LQD",
		 "TIME": "Sat 06:30:PM"
	},
	{
		 "CITY": "Oklahoma City",
		 "STATION": "K13XU",
		 "TIME": "Sat 06:30:PM"
	},
	{
		 "CITY": "Oklahoma City",
		 "STATION": "KSBIDT",
		 "TIME": "Sat 06:30:PM"
	},
	{
		 "CITY": "Oklahoma City",
		 "STATION": "KSBI",
		 "TIME": "Mon 07:00:AM"
	},
	{
		 "CITY": "Omaha",
		 "STATION": "KETVDT",
		 "TIME": "Sun 03:30:AM"
	},
	{
		 "CITY": "Omaha",
		 "STATION": "KETV",
		 "TIME": "Sat 02:30:PM"
	},
	{
		 "CITY": "Palm Springs",
		 "STATION": "KPSELD",
		 "TIME": "Sun 02:30:PM"
	},
	{
		 "CITY": "Palm Springs",
		 "STATION": "KPSELP",
		 "TIME": "Sat 05:30:AM"
	},
	{
		 "CITY": "Philadelphia",
		 "STATION": "WACPDT6",
		 "TIME": "Sat 05:00:PM"
	},
	{
		 "CITY": "Philadelphia",
		 "STATION": "WACPDT2",
		 "TIME": "Sat 10:00:AM"
	},
	{
		 "CITY": "Philadelphia",
		 "STATION": "WACPDT2",
		 "TIME": "Tue 10:00:PM"
	},
	{
		 "CITY": "Philadelphia",
		 "STATION": "WACPDT6",
		 "TIME": "Sun 05:00:PM"
	},
	{
		 "CITY": "Philadelphia",
		 "STATION": "WACPDT2",
		 "TIME": "Sun 10:00:AM"
	},
	{
		 "CITY": "Philadelphia",
		 "STATION": "WACPDT2",
		 "TIME": "Sat 04:00:PM"
	},
	{
		 "CITY": "Phoenix (Prescott)",
		 "STATION": "K14RKD2",
		 "TIME": "Sat 11:30:AM"
	},
	{
		 "CITY": "Phoenix (Prescott)",
		 "STATION": "KKAXLP",
		 "TIME": "Sat 07:00:AM"
	},
	{
		 "CITY": "Phoenix (Prescott)",
		 "STATION": "K14RKD2",
		 "TIME": "Fri 04:30:PM"
	},
	{
		 "CITY": "Phoenix (Prescott)",
		 "STATION": "KKAXLP",
		 "TIME": "Thu 04:30:PM"
	},
	{
		 "CITY": "Phoenix (Prescott)",
		 "STATION": "KKAXLP",
		 "TIME": "Wed 04:30:PM"
	},
	{
		 "CITY": "Phoenix (Prescott)",
		 "STATION": "KKAXLP",
		 "TIME": "Tue 04:30:PM"
	},
	{
		 "CITY": "Phoenix (Prescott)",
		 "STATION": "KKAXLP",
		 "TIME": "Mon 04:30:PM"
	},
	{
		 "CITY": "Phoenix (Prescott)",
		 "STATION": "KKAXLP",
		 "TIME": "Sun 02:00:PM"
	},
	{
		 "CITY": "Phoenix (Prescott)",
		 "STATION": "K14RKD2",
		 "TIME": "Sun 11:30:AM"
	},
	{
		 "CITY": "Phoenix (Prescott)",
		 "STATION": "KKAXLP",
		 "TIME": "Sun 07:00:AM"
	},
	{
		 "CITY": "Phoenix (Prescott)",
		 "STATION": "K14RKD2",
		 "TIME": "Sun 12:00:PM"
	},
	{
		 "CITY": "Pittsburgh",
		 "STATION": "WBPALD3",
		 "TIME": "Sat 05:30:PM"
	},
	{
		 "CITY": "Pittsburgh",
		 "STATION": "WIICLD3",
		 "TIME": "Tue 10:00:PM"
	},
	{
		 "CITY": "Pittsburgh",
		 "STATION": "WBPALD3",
		 "TIME": "Tue 10:00:PM"
	},
	{
		 "CITY": "Pittsburgh",
		 "STATION": "WIICLD3",
		 "TIME": "Mon 08:00:AM"
	},
	{
		 "CITY": "Pittsburgh",
		 "STATION": "WBPALD2",
		 "TIME": "Mon 08:00:AM"
	},
	{
		 "CITY": "Pittsburgh",
		 "STATION": "WIICLD2",
		 "TIME": "Sat 01:30:PM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KVDOLD2",
		 "TIME": "Sat 11:30:AM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KPWCLD2",
		 "TIME": "Sat 11:30:AM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KSLMLD2",
		 "TIME": "Sat 11:30:AM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KWVTLD2",
		 "TIME": "Fri 04:30:PM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KVDOLD2",
		 "TIME": "Fri 04:30:PM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KPWCLD2",
		 "TIME": "Fri 04:30:PM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KSLMLD2",
		 "TIME": "Fri 04:30:PM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KWVTLD2",
		 "TIME": "Thu 04:30:PM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KVDOLD2",
		 "TIME": "Thu 04:30:PM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KPWCLD2",
		 "TIME": "Thu 04:30:PM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KSLMLD2",
		 "TIME": "Thu 04:30:PM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KWVTLD2",
		 "TIME": "Wed 04:30:PM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KVDOLD2",
		 "TIME": "Wed 04:30:PM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KPWCLD2",
		 "TIME": "Wed 04:30:PM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KSLMLD2",
		 "TIME": "Wed 04:30:PM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KWVTLD2",
		 "TIME": "Tue 04:30:PM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KVDOLD2",
		 "TIME": "Tue 04:30:PM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KPWCLD2",
		 "TIME": "Tue 04:30:PM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KSLMLD2",
		 "TIME": "Tue 04:30:PM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KWVTLD2",
		 "TIME": "Mon 04:30:PM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KVDOLD2",
		 "TIME": "Mon 04:30:PM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KPWCLD2",
		 "TIME": "Mon 04:30:PM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KSLMLD2",
		 "TIME": "Mon 04:30:PM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KWVTLD2",
		 "TIME": "Sun 11:30:AM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KVDOLD2",
		 "TIME": "Sun 11:30:AM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KPWCLD2",
		 "TIME": "Sun 11:30:AM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KSLMLD2",
		 "TIME": "Sun 11:30:AM"
	},
	{
		 "CITY": "Portland, OR",
		 "STATION": "KWVTLD2",
		 "TIME": "Sat 01:30:PM"
	},
	{
		 "CITY": "Quincy-Hannibal-Keokuk",
		 "STATION": "WGEMDT3",
		 "TIME": "Sun 09:00:AM"
	},
	{
		 "CITY": "Raleigh-Durham (Fayetvlle)",
		 "STATION": "WAUGLD",
		 "TIME": "Sat 02:30:PM"
	},
	{
		 "CITY": "Raleigh-Durham (Fayetvlle)",
		 "STATION": "WAUGLP",
		 "TIME": "Fri 07:30:PM"
	},
	{
		 "CITY": "Raleigh-Durham (Fayetvlle)",
		 "STATION": "WAUGLD",
		 "TIME": "Fri 07:30:PM"
	},
	{
		 "CITY": "Raleigh-Durham (Fayetvlle)",
		 "STATION": "WAUGLP",
		 "TIME": "Thu 07:30:PM"
	},
	{
		 "CITY": "Raleigh-Durham (Fayetvlle)",
		 "STATION": "WAUGLD",
		 "TIME": "Thu 07:30:PM"
	},
	{
		 "CITY": "Raleigh-Durham (Fayetvlle)",
		 "STATION": "WAUGLP",
		 "TIME": "Wed 07:30:PM"
	},
	{
		 "CITY": "Raleigh-Durham (Fayetvlle)",
		 "STATION": "WAUGLD",
		 "TIME": "Wed 07:30:PM"
	},
	{
		 "CITY": "Raleigh-Durham (Fayetvlle)",
		 "STATION": "WAUGLP",
		 "TIME": "Tue 07:30:PM"
	},
	{
		 "CITY": "Raleigh-Durham (Fayetvlle)",
		 "STATION": "WAUGLD",
		 "TIME": "Tue 07:30:PM"
	},
	{
		 "CITY": "Raleigh-Durham (Fayetvlle)",
		 "STATION": "WAUGLP",
		 "TIME": "Mon 07:30:PM"
	},
	{
		 "CITY": "Raleigh-Durham (Fayetvlle)",
		 "STATION": "WAUGLD",
		 "TIME": "Mon 07:30:PM"
	},
	{
		 "CITY": "Raleigh-Durham (Fayetvlle)",
		 "STATION": "WAUGLP",
		 "TIME": "Sun 02:30:PM"
	},
	{
		 "CITY": "Raleigh-Durham (Fayetvlle)",
		 "STATION": "WAUGLD",
		 "TIME": "Sun 02:30:PM"
	},
	{
		 "CITY": "Raleigh-Durham (Fayetvlle)",
		 "STATION": "WAUGLP",
		 "TIME": "Sat 05:30:PM"
	},
	{
		 "CITY": "Rapid City",
		 "STATION": "KRPCLD5",
		 "TIME": "Tue 08:00:PM"
	},
	{
		 "CITY": "Rapid City",
		 "STATION": "KRPCLD5",
		 "TIME": "Mon 07:00:AM"
	},
	{
		 "CITY": "Rapid City",
		 "STATION": "KRPCLD4",
		 "TIME": "Sun 09:00:AM"
	},
	{
		 "CITY": "Reno",
		 "STATION": "KRMFLD3",
		 "TIME": "Sat 04:30:PM"
	},
	{
		 "CITY": "Rochester, NY",
		 "STATION": "WBGTCD",
		 "TIME": "Sat 01:00:PM"
	},
	{
		 "CITY": "Rochester, NY",
		 "STATION": "W26BZ",
		 "TIME": "Sat 01:00:PM"
	},
	{
		 "CITY": "Rochester, NY",
		 "STATION": "WBGTCA",
		 "TIME": "Mon 08:00:AM"
	},
	{
		 "CITY": "Rochester, NY",
		 "STATION": "WBGTCD3",
		 "TIME": "Sun 06:30:AM"
	},
	{
		 "CITY": "Rochester, NY",
		 "STATION": "WBGTCD",
		 "TIME": "Sun 06:30:AM"
	},
	{
		 "CITY": "Rochester, NY",
		 "STATION": "W26BZ",
		 "TIME": "Sun 06:30:AM"
	},
	{
		 "CITY": "Rochester, NY",
		 "STATION": "WBGTCA",
		 "TIME": "Sat 11:30:PM"
	},
	{
		 "CITY": "Rochestr-Mason City-Austin",
		 "STATION": "KIMTDT2",
		 "TIME": "Sun 03:33:AM"
	},
	{
		 "CITY": "Rochestr-Mason City-Austin",
		 "STATION": "KIMTDT",
		 "TIME": "Sun 03:33:AM"
	},
	{
		 "CITY": "Rochestr-Mason City-Austin",
		 "STATION": "KIMT",
		 "TIME": "Sat 03:30:PM"
	},
	{
		 "CITY": "San Antonio",
		 "STATION": "KRTXLD5",
		 "TIME": "Tue 09:00:PM"
	},
	{
		 "CITY": "San Antonio",
		 "STATION": "KRTXLD5",
		 "TIME": "Mon 07:00:AM"
	},
	{
		 "CITY": "San Antonio",
		 "STATION": "KRTXLD4",
		 "TIME": "Sat 05:30:PM"
	},
	{
		 "CITY": "Seattle-Tacoma",
		 "STATION": "K48LLD2",
		 "TIME": "Sat 05:30:PM"
	},
	{
		 "CITY": "South Bend-Elkhart",
		 "STATION": "WHMEDT",
		 "TIME": "Sat 12:00:PM"
	},
	{
		 "CITY": "South Bend-Elkhart",
		 "STATION": "WHME",
		 "TIME": "Mon 08:00:AM"
	},
	{
		 "CITY": "St. Louis",
		 "STATION": "KDKZLD",
		 "TIME": "Wed 06:30:PM"
	},
	{
		 "CITY": "St. Louis",
		 "STATION": "KDKZLD",
		 "TIME": "Sun 01:30:PM"
	},
	{
		 "CITY": "St. Louis",
		 "STATION": "KDKZLD",
		 "TIME": "Sat 02:30:PM"
	},
	{
		 "CITY": "Syracuse",
		 "STATION": "WWLFLD",
		 "TIME": "Sat 02:30:PM"
	},
	{
		 "CITY": "Syracuse",
		 "STATION": "WONOCD",
		 "TIME": "Sat 02:30:PM"
	},
	{
		 "CITY": "Syracuse",
		 "STATION": "WOBXLP",
		 "TIME": "Fri 07:30:PM"
	},
	{
		 "CITY": "Syracuse",
		 "STATION": "WWLFLD",
		 "TIME": "Fri 07:30:PM"
	},
	{
		 "CITY": "Syracuse",
		 "STATION": "WONOCD",
		 "TIME": "Fri 07:30:PM"
	},
	{
		 "CITY": "Syracuse",
		 "STATION": "WOBXLP",
		 "TIME": "Thu 07:30:PM"
	},
	{
		 "CITY": "Syracuse",
		 "STATION": "WWLFLD",
		 "TIME": "Thu 07:30:PM"
	},
	{
		 "CITY": "Syracuse",
		 "STATION": "WONOCD",
		 "TIME": "Thu 07:30:PM"
	},
	{
		 "CITY": "Syracuse",
		 "STATION": "WOBXLP",
		 "TIME": "Wed 07:30:PM"
	},
	{
		 "CITY": "Syracuse",
		 "STATION": "WWLFLD",
		 "TIME": "Wed 07:30:PM"
	},
	{
		 "CITY": "Syracuse",
		 "STATION": "WONOCD",
		 "TIME": "Wed 07:30:PM"
	},
	{
		 "CITY": "Syracuse",
		 "STATION": "WOBXLP",
		 "TIME": "Tue 07:30:PM"
	},
	{
		 "CITY": "Syracuse",
		 "STATION": "WWLFLD",
		 "TIME": "Tue 07:30:PM"
	},
	{
		 "CITY": "Syracuse",
		 "STATION": "WONOCD",
		 "TIME": "Tue 07:30:PM"
	},
	{
		 "CITY": "Syracuse",
		 "STATION": "WOBXLP",
		 "TIME": "Mon 07:30:PM"
	},
	{
		 "CITY": "Syracuse",
		 "STATION": "WWLFLD",
		 "TIME": "Mon 07:30:PM"
	},
	{
		 "CITY": "Syracuse",
		 "STATION": "WONOCD",
		 "TIME": "Mon 07:30:PM"
	},
	{
		 "CITY": "Syracuse",
		 "STATION": "WOBXLP",
		 "TIME": "Sun 02:30:PM"
	},
	{
		 "CITY": "Syracuse",
		 "STATION": "WWLFLD",
		 "TIME": "Sun 02:30:PM"
	},
	{
		 "CITY": "Syracuse",
		 "STATION": "WONOCD",
		 "TIME": "Sun 02:30:PM"
	},
	{
		 "CITY": "Syracuse",
		 "STATION": "WOBXLP",
		 "TIME": "Sun 02:30:PM"
	},
	{
		 "CITY": "Syracuse",
		 "STATION": "WISFLP",
		 "TIME": "Sun 01:00:PM"
	},
	{
		 "CITY": "Tampa-St. Pete (Sarasota)",
		 "STATION": "WDNPLD5",
		 "TIME": "Tue 10:00:PM"
	},
	{
		 "CITY": "Tampa-St. Pete (Sarasota)",
		 "STATION": "WDNPLD5",
		 "TIME": "Mon 08:00:AM"
	},
	{
		 "CITY": "Tampa-St. Pete (Sarasota)",
		 "STATION": "WDNPLD4",
		 "TIME": "Sun 01:00:PM"
	},
	{
		 "CITY": "Tampa-St. Pete (Sarasota)",
		 "STATION": "WSPFCD2",
		 "TIME": "Sat 11:30:AM"
	},
	{
		 "CITY": "Toledo",
		 "STATION": "WNHOLD2",
		 "TIME": "Fri 02:00:PM"
	},
	{
		 "CITY": "Toledo",
		 "STATION": "WNHOLD",
		 "TIME": "Tue 12:30:PM"
	},
	{
		 "CITY": "Toledo",
		 "STATION": "WNHOLD",
		 "TIME": "Sat 12:30:PM"
	},
	{
		 "CITY": "Topeka",
		 "STATION": "WROBLD4",
		 "TIME": "Sat 05:30:PM"
	},
	{
		 "CITY": "Traverse City-Cadillac",
		 "STATION": "WUROLD6",
		 "TIME": "Sat 08:30:AM"
	},
	{
		 "CITY": "Twin Falls",
		 "STATION": "KCTFLP",
		 "TIME": "Tue 08:00:PM"
	},
	{
		 "CITY": "Twin Falls",
		 "STATION": "KCTFLP",
		 "TIME": "Mon 06:00:AM"
	},
	{
		 "CITY": "Twin Falls",
		 "STATION": "KYTLLD2",
		 "TIME": "Sat 11:30:PM"
	},
	{
		 "CITY": "Victoria",
		 "STATION": "KMOLLD",
		 "TIME": "Sun 05:30:AM"
	},
	{
		 "CITY": "Victoria",
		 "STATION": "KAVUDT2",
		 "TIME": "Sun 05:30:AM"
	},
	{
		 "CITY": "Victoria",
		 "STATION": "KMOLLP",
		 "TIME": "Sat 12:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "KSMILD5",
		 "TIME": "Sat 04:00:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "KGPTCD3",
		 "TIME": "Sat 01:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K39KOD6",
		 "TIME": "Sat 01:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K33KMD6",
		 "TIME": "Sat 01:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K44JVD6",
		 "TIME": "Sat 01:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K32JFD6",
		 "TIME": "Sat 01:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "KDGLLD6",
		 "TIME": "Sat 01:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "KCTULD2",
		 "TIME": "Sat 09:00:AM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "KGPTCD3",
		 "TIME": "Fri 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K39KOD6",
		 "TIME": "Fri 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K33KMD6",
		 "TIME": "Fri 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K44JVD6",
		 "TIME": "Fri 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K32JFD6",
		 "TIME": "Fri 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "KDGLLD6",
		 "TIME": "Fri 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "KCTULD2",
		 "TIME": "Thu 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K39KOD6",
		 "TIME": "Thu 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K33KMD6",
		 "TIME": "Thu 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K44JVD6",
		 "TIME": "Thu 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K32JFD6",
		 "TIME": "Thu 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "KDGLLD6",
		 "TIME": "Thu 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "KCTULD2",
		 "TIME": "Wed 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K39KOD6",
		 "TIME": "Wed 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K33KMD6",
		 "TIME": "Wed 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K44JVD6",
		 "TIME": "Wed 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K32JFD6",
		 "TIME": "Wed 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "KDGLLD6",
		 "TIME": "Tue 09:00:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "KSMILD5",
		 "TIME": "Tue 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K39KOD6",
		 "TIME": "Tue 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K33KMD6",
		 "TIME": "Tue 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K44JVD6",
		 "TIME": "Tue 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K32JFD6",
		 "TIME": "Tue 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "KDGLLD6",
		 "TIME": "Tue 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "KCTULD2",
		 "TIME": "Mon 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K39KOD6",
		 "TIME": "Mon 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K33KMD6",
		 "TIME": "Mon 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K44JVD6",
		 "TIME": "Mon 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K32JFD6",
		 "TIME": "Mon 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "KDGLLD6",
		 "TIME": "Mon 06:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "KCTULD2",
		 "TIME": "Mon 07:00:AM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "KSMILD4",
		 "TIME": "Sun 04:00:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "KGPTCD3",
		 "TIME": "Sun 01:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K39KOD6",
		 "TIME": "Sun 01:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K33KMD6",
		 "TIME": "Sun 01:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K44JVD6",
		 "TIME": "Sun 01:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "K32JFD6",
		 "TIME": "Sun 01:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "KDGLLD6",
		 "TIME": "Sun 01:30:PM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "KCTULD2",
		 "TIME": "Sun 09:00:AM"
	},
	{
		 "CITY": "Wichita-Hutchinson Plus",
		 "STATION": "KGPTCD3",
		 "TIME": "Sun 03:30:AM"
	},
	{
		 "CITY": "Youngstown",
		 "STATION": "WYFXLD2",
		 "TIME": "Sat 08:30:AM"
	},
	{
		 "CITY": "Youngstown",
		 "STATION": "WYTVDT2",
		 "TIME": "Sat 01:30:PM"
	}
]

export default markets;
