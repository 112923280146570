<form class="insinv-form" [formGroup]="formGroup">
	<mat-card class="insinv-form-card insinv-card">
		<div class="insinv-form-header">
			<mat-card-title>
				<ng-content select="insinv-form-title"></ng-content>
			</mat-card-title>
			<mat-card-subtitle>
				<ng-content select="insinv-form-subtitle"></ng-content>
			</mat-card-subtitle>
		</div>
		<mat-card-content>
			<ng-content select="insinv-form-content"></ng-content>
			<ng-content></ng-content>
		</mat-card-content>

		<mat-card-actions>
			<ng-content select="insinv-form-actions"></ng-content>
		</mat-card-actions>
	</mat-card>
</form>
