<div id="dales-book" fxLayout="row wrap">
	<div class="video-wrapper px-md-32" fxFlex="100%" fxFlex.gt-sm="50%">
		<iframe width="560" height="315" src="https://www.youtube.com/embed/6iVnd4NlZW8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
	</div>

	<div class="dales-book-content px-md-32" fxLayout="column" fxFlex="100%" fxFlex.gt-sm="50%">
		<div class="book-content-title page-header left mb-32" fxFlex="0 1 auto">
			<h3 class="page-title m-0">Dale's New Book</h3>
		</div>

		<div class="book-content-subtitle mb-20" fxFlex="0 1 auto">
			<h2 class="accent-fg m-0">Don't Get Scammed: Get Smart!</h2>
		</div>

		<div class="book-content-copy mb-20" fxFlex="1 1 auto">
			SCAMMERS BEWARE: SEASONED CONSUMER INVESTIGATOR DALE CARDWELL GIVES READERS A GAME PLAN TO AVOID RIP-OFFS
		</div>

		<div class="book-content-footer" fxFlex="0 1 auto">
			<a mat-raised-button color="primary" href="https://www.trustdale.com/dales-book" title="Get Dale's Book">Read More</a>
		</div>
	</div>
</div>
