import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Playlist } from 'app/models/playlist.model';
import { VideoService } from 'app/services/video.service';
import { finalize, map, tap } from 'rxjs/operators';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Video } from './video';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
	selector: 'series-list',
	templateUrl: './series-list.component.html',
	styleUrls: ['./series-list.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class SeriesListComponent implements OnInit {

	@Input('series')
	series: Playlist;

	@Output('slideClicked') slideClicked: EventEmitter<any>;

	videos$: Observable<Video[]>;

	loading: boolean = false;

	options: OwlOptions = {
		autoWidth: true,
		autoHeight: false,
		loop: false,
		mouseDrag: false,
		touchDrag: true,
		skip_validateItems: true,
		pullDrag: true,
		dots: false,
		navSpeed: 700,
		nav: true,
		margin: 20,
		navText: ['Prev', 'Next'],
		responsive: {
			0: {
				items: 1
			},
			420: {
				items: 2
			},
			720: {
				items: 4
			}
		}
	}

	constructor(
		private _videoService: VideoService,
	) {
		this.slideClicked = new EventEmitter();
	}

	ngOnInit(): void {
		this.videos$ = this._videoService.getPlaylistVideos(this.series.host_id).pipe(
			finalize( () => this.loading = false),
			map(response => response.data.map(video => new Video(video)))
		)
	}

	onSlideClicked(videoId: string): void {
		this.slideClicked.emit(videoId);
	}

	get items(): number[] {
		return new Array(this.series.video_count);
	}

}
