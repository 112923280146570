import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { InsinvFormActions, InsinvFormComponent, InsinvFormContent, InsinvFormSubtitle, InsinvFormTitle } from "./insinv-form.component";

@NgModule({
	declarations: [
		InsinvFormComponent,
		InsinvFormContent,
		InsinvFormTitle,
		InsinvFormSubtitle,
		InsinvFormActions
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,

		MatCardModule
	],
	exports: [
		InsinvFormComponent,
		InsinvFormContent,
		InsinvFormTitle,
		InsinvFormSubtitle,
		InsinvFormActions
	]
})
export class InsinvFormModule { }
