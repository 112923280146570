<div class="content-container content-container-lg">
	<div id="mass-tort">

		<header class="page-header left">
			<h1 class="page-title">Have you or a loved one has been hurt by a defective medical device or a dangerous product or medication?</h1>
		</header>

		<div class="page-banner">
			<img src="assets/mass-tort-banner.jpg" alt="weed-killer">
		</div>

		<div class="page-body">

			<section class="header-row">
				<div class="header-row-icon">
					<img src="assets/call-icon.png" alt="call-icon">
				</div>
				<div class="header-row-text">
					<span class="mat-display-1">Call today to speak with experienced advocates or complete the form below.</span>
				</div>
			</section>

			<section class="call-details">
				<ul class="call-detail-list">
					<li>For Women's Health Products such as talcum powder or an IUD call <a href="tel:4708264203">470-826-4203</a></li>
					<li>For Bair Hugger surgical warming blanket, bone infections after knee or hip surgery call <a href="tel:4708264202">470-826-4202</a></li>
					<li>For Paraquat or Round Up toxic herbicides causing Parkinson’s disease or Non-Hodgkin’s Lymphoma call <a href="tel:4708575738">470-857-5738</a></li>
					<li>For severe intestinal issues in your premature baby who was fed Enfamil or Similac cow milk formula call <a href="tel:4706322014">470-632-2014</a></li>
				</ul>
			</section>

			<section class="contact-container">
				<div class="header-row">
					<div class="header-row-icon">
						<img src="assets/email-icon.png" alt="email-icon">
					</div>
					<div class="header-row-text">
						<span class="mat-display-1">Reach out to us with your questions and concerns and we’ll respond as soon as possible.</span>
					</div>
				</div>

				<div class="contact-form-container">

					<insinv-form [formGroup]="form">

						<insinv-form-content>

							<section class="form-section">

								<div class="form-group mb-24">
									<div class="mb-12">Check the product(s) that you or a loved one may have been harmed by:</div>
									<div class="product-list" formGroupName="products">
										<mat-checkbox formControlName="Paraquat Herbicide">
											Paraquat Herbicide
										</mat-checkbox>
										<mat-checkbox formControlName="Infant Formula">
											Infant formula
										</mat-checkbox>
										<mat-checkbox formControlName="Johnson and Johnson Baby Powder">
											Johnson and Johnson Baby Powder
										</mat-checkbox>
										<mat-checkbox formControlName="Paragard IUD">
											Paragard IUD
										</mat-checkbox>
										<mat-checkbox formControlName="Bair Hugger Surgical Warming Blanket">
											Bair Hugger Surgical Warming Blanket
										</mat-checkbox>
									</div>
									<mat-error *ngIf="form.get('products').hasError('required')">You must select at least one option.</mat-error>
								</div>

								<div class="form-group">
									<mat-form-field fxFlex="100%" fxFlex.gt-xs="1 1 auto">
										<mat-label>Full Name</mat-label>
										<input matInput name="name" formControlName="name" placeholder="Your Name" required>
										<mat-error>This field is required.</mat-error>
									</mat-form-field>
								</div>

								<div class="form-group">
									<mat-form-field fxFlex="100%" fxFlex.gt-xs="1 1 auto">
										<mat-label>Email Address</mat-label>
										<input matInput type="email" formControlName="email" placeholder="Your Email Address" required>
										<mat-error *ngIf="form.get('email').hasError('required')">This field is required.</mat-error>
										<mat-error *ngIf="form.get('email').hasError('email')">Invalid email address.</mat-error>
									</mat-form-field>
								</div>

								<div class="form-group">
									<mat-form-field fxFlex="100%" fxFlex.gt-xs="1 1 auto">
										<mat-label>Phone Number</mat-label>
										<input matInput name="phone" formControlName="phone" placeholder="Your Phone Number" required>
										<mat-error>This field is required.</mat-error>
									</mat-form-field>
								</div>

								<div fxLayout="row wrap" fxLayoutAlign="center start">

									<div fxLayout="column" fxFlex="100%" fxFlex.gt-xs="1 1 50%">

										<mat-form-field fxFlex class="w-100-p">
											<mat-label>Street Address</mat-label>
											<input matInput formControlName="address_1" placeholder="Street Address" required>
											<mat-error>This field is required.</mat-error>
										</mat-form-field>

										<mat-form-field fxFlex class="w-100-p">
											<mat-label>Address Line 2</mat-label>
											<input matInput formControlName="address_2" placeholder="Apt, Suite, Floor">
										</mat-form-field>

									</div>

									<div fxLayout="column" fxFlex="100%" fxFlex.gt-xs="1 1 50%">

										<mat-form-field fxFlex class="px-16 w-100-p">
											<mat-label>City</mat-label>
											<input matInput formControlName="city" placeholder="City" required>
											<mat-error>This field is required.</mat-error>
										</mat-form-field>

										<div fxLayout="row wrap" fxFlex="100%">

											<mat-form-field fxFlex fxFlex.gt-xs="50%">
												<mat-label>State</mat-label>
												<input matInput formControlName="state" placeholder="State" required>
												<mat-error>This field is required.</mat-error>
											</mat-form-field>

											<mat-form-field fxFlex fxFlex.gt-xs="50%">
												<mat-label>Zipcode</mat-label>
												<input matInput formControlName="zipcode" placeholder="Zipcode" required>
												<mat-error>This field is required.</mat-error>
											</mat-form-field>

										</div>

									</div>

								</div>

								<div class="form-group">
									<mat-form-field class="w-100-p mb-20">
										<mat-label>Tell us in your own words how you or a loved one were injured</mat-label>
										<textarea matInput formControlName="message" maxlength="1200" rows="5" #messageInput
											cdkTextareaAutosize cdkAutosizeMaxRows="15" required></textarea>
										<mat-hint align="end">{{messageInput.value?.length || 0}} / 1200</mat-hint>
										<mat-error>This field is required.</mat-error>
									</mat-form-field>
								</div>

							</section>

						</insinv-form-content>

						<insinv-form-actions>
							<button class="mb-12" mat-raised-button (click)="submitForm()" [disabled]="submitting || sent || form.disabled">{{ buttonText }}</button>
						</insinv-form-actions>

					</insinv-form>

				</div>
			</section>

		</div>

		<div class="page-footer">
			Inside Investigations’ Tort Report initiative is attorney advertising. The initiative is sponsored by Cohen Hirsch, LP. The firm’s contact is Andrea S Hirsch. The firm is headquartered at 5256 Peachtree Road, Suite 195-E, Atlanta, GA 30341.  Cohen Hirsch, LP is not accepting cases in all states including any state where it would be impermissible for it to do so.  Cohen Hirsch, LP may be associated with other law firms in other states and jurisdictions as required.  Brooke F Cohen is licensed in Arizona and Texas.  Andrea S Hirsch is licensed in Georgia.  Attorneys are not certified by the Boards of Legal Specialization in any state.  Not all states have board certifications.  If legal services are sought, individuals will be directed to the above law firm and ONLY when an attorney-client relationship has been established will legal services be provided by Cohen Hirsch or other law firms with which they may affiliate. Additionally, prior results do not guarantee future outcomes. If there is no recovery (compensation), no fees or costs are charged by these law firms. No representation is made that the quality of the legal services to be performed is greater than the quality of legal services performed by other lawyers.
		</div>

	</div>
</div>
