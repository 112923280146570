<div id="video-carousel" [ngClass]="{ animate: animate }">

	<div class="carousel-wrapper">

		<div class="video-carousel-loading-wrapper" [fxShow]="loading">

			<mat-progress-spinner [diameter]="40" mode="indeterminate"></mat-progress-spinner>

		</div>

		<owl-carousel-o #carousel [options]="options" *ngIf="!loading" (translated)="onCarouselTranslated($event)" (change)="onCarouselChange($event)">
			<ng-container *ngFor="let video of videos">
				<ng-template carouselSlide>
					<div class="slide" #slideRef (click)="onSlideClicked(video.id)">

						<div class="image-container">
							<img [src]="video.images['maxres']" [alt]="video.title" [title]="video.title">
						</div>

						<div class="slide-content">

							<div class="slide-header">
								<h1 class="video-title mb-sm-4">{{ video.title }}</h1>
							</div>
							<div class="slide-body">
								<p class="video-description" fxHide.lt-sm>{{ video.description }}</p>
							</div>

						</div>
					</div>
				</ng-template>
			</ng-container>
		</owl-carousel-o>

	</div>
</div>
