import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { insinvAnimations } from '@insinv/animations';

@Component({
  selector: 'content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: insinvAnimations
})
export class ContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
