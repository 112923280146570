import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { InsInvNavigationService } from '@insinv/components/navigation/navigation.service';
import { InsInvConfigService } from '@insinv/services/config.service';
import { Subject } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { navigation } from './navigation/navigation';
import { takeUntil, filter, map } from 'rxjs/operators';
import { InsInvConfig } from '@insinv/types';
import { IpAddressService } from './services/ip-address.service';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { StoryButtonComponent } from './components/story-button/story-button.component';
import { LayoutService } from './layout/layout.service';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';

//declare gives Angular app access to ga function
declare let gtag: Function;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

	insinvConfig: any;
	navigation: any;
	title = 'Inside Investigations';

	private _storyButtonRef: MatSnackBarRef<any>;

	// Private
	private _unsubscribeAll: Subject<any>;

	constructor(
		@Inject(DOCUMENT) private _document: any,
		private _location: Location,
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		private _insinvConfigService: InsInvConfigService,
		private _insinvNavigationService: InsInvNavigationService,
		private _ipAddressService: IpAddressService,
		private _platform: Platform,
		private _matSnackbar: MatSnackBar,
		private _layoutService: LayoutService,
		private _titleService: Title,
		private _metaService: Meta
	) {
		// Get default navigation
		this.navigation = navigation;

		// Register the navigation to the service
		this._insinvNavigationService.register('main', this.navigation);

		// Set the main navigation as our current navigation
		this._insinvNavigationService.setCurrentNavigation('main');

		// Add is-mobile class to the body if the platform is mobile
		if (this._platform.ANDROID || this._platform.IOS) {
			this._document.body.classList.add('is-mobile');
		}

		// Set the private defaults
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void {
		// Update google analytics on page changes
		if( environment.production ) {
			this._router.events
				.pipe(takeUntil(this._unsubscribeAll))
				.subscribe(event => {
					if (event instanceof NavigationEnd) {
						gtag('config', 'UA-9518898-7', { 'page_path': event.urlAfterRedirects });
					}
				})
		}

		// Subscribe to config changes
		this._insinvConfigService.config
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((config: InsInvConfig) => this.insinvConfig = config);

		this.registerPageTitleService();

		this.registerStoryButton();
	}

	showStoryButton(): void {
		this._storyButtonRef = this._matSnackbar.openFromComponent(StoryButtonComponent, {
			horizontalPosition: 'end',
			verticalPosition: 'bottom',
			panelClass: ['story-button', 'accent']
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Toggle sidebar open
	 *
	 * @param key
	 */
	toggleSidebarOpen(key): void {
		//this._insinvSidebarService.getSidebar(key).toggleOpen();
	}

	registerPageTitleService(): void {
		const appTitle = this._titleService.getTitle();
		const appDescription = this._metaService.getTag('name="description"');
		this._router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				map(() => {
					const meta = {
						title: appTitle,
						description: appDescription.content
					}
					const child = this._activatedRoute.firstChild;
					if (child.snapshot.data['title']) {
						meta.title = child.snapshot.data['title'];
					}
					if (child.snapshot.data['description']) {
						meta.description = child.snapshot.data['description'];
					}
					return meta;
				}),
				takeUntil(this._unsubscribeAll)
			).subscribe((meta: any) => {
				this._titleService.setTitle(meta.title);
				this._metaService.updateTag({ content: meta.description }, 'name="description"');
			});
	}

	registerStoryButton(): void {
		this._layoutService.onFooterVpChange$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(([inView, footerElement]: [boolean, ElementRef]) => {
				if (!this._storyButtonRef) return;
				var el = this._storyButtonRef.instance.element.nativeElement as Element;
				if (inView) {
					this._storyButtonRef.dismiss();
				} else if (this._location.path() !== '/contact-us') {
					this.showStoryButton();
				}
			})

		this._location.onUrlChange(() => {

			// Show the Story Button
			if (this._location.path() !== '/contact-us') {
				this.showStoryButton();
			} else if (this._storyButtonRef) {
				this._storyButtonRef.dismiss();
			}

		});
	}

}
