<div id="main-footer" class="footer" #footerRef>
	<div snInViewport
		(inViewportChange)="onFooterVpChange($event)"
		class="footer-container">

		<address>
			<p>3300 NE Expy NE</p>
			<p>Bldg 4, 100</p>
			<p>Atlanta, GA 30341</p>
		</address>
		<div class="contact-email">
			<p>Contact: <a href="mailto:insideinvestigations@trustdale.com" title="Email">insideinvestigations@trustdale.com</a></p>
		</div>
		<a [routerLink]="['/privacy-policy']" title="Privacy Policy">Privacy Policy</a>
	</div>
</div>
