import { NgModule } from '@angular/core';

import { InsInvSharedModule } from '@insinv/shared.module';

import { NavbarComponent } from './navbar.component';
import { NavbarHorizontalModule } from './horizontal/nav-horizontal.module';
import { NavbarVerticalModule } from './vertical/nav-vertical.module';

@NgModule({
    declarations: [
        NavbarComponent
    ],
    imports     : [
        InsInvSharedModule,

        NavbarHorizontalModule,
        NavbarVerticalModule,
    ],
    exports     : [
        NavbarComponent
    ]
})
export class NavbarModule
{
}
