import { Component, OnInit } from '@angular/core';
import { insinvAnimations } from '@insinv/animations';

@Component({
  selector: 'error-404',
  templateUrl: './error-404.component.html',
  styleUrls: ['./error-404.component.scss'],
  animations: insinvAnimations
})
export class Error404Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
