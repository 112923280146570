<div class="content-container">
	<div id="contact-us" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">
		<header class="page-header">
			<h1 class="page-title">Contact Us</h1>
		</header>

		<div class="mat-headline text-center mb-16">
			<span>Are you the victim of a scam? Do you have something you would like us to investigate?</span>
			<span>Complete this form to contact the Inside Investigations team.</span>
		</div>


		<mat-card class="insinv-card form-wrapper">

			<form name="contact-us" [formGroup]="form">

				<section class="form-section">

					<div class="form-section-header">
						<div class="form-section-title">
							YOUR INFORMATION
						</div>
					</div>

					<div class="form-section-body">

						<div class="mb-20" fxLayout="row wrap" fxLayoutAlign="center center">

							<mat-form-field fxFlex="100%" fxFlex.gt-xs="1 1 50%">
								<mat-label>First Name</mat-label>
								<input type="text" matInput formControlName="first_name" placeholder="First Name" required>
								<mat-error>This field is required.</mat-error>
							</mat-form-field>

							<mat-form-field fxFlex="100%" fxFlex.gt-xs="1 1 50%">
								<mat-label>Last Name</mat-label>
								<input type="text" matInput formControlName="last_name" placeholder="Last Name" required>
								<mat-error>This field is required.</mat-error>
							</mat-form-field>

							<mat-form-field fxFlex="100%" fxFlex.gt-xs="1 1 50%">
								<mat-label>Email Address</mat-label>
								<input type="email" matInput formControlName="email" placeholder="Email Address" required>
								<mat-error *ngIf="form.get('email').hasError('required')">This field is required.</mat-error>
								<mat-error *ngIf="form.get('email').hasError('email')">Invalid Email.</mat-error>
							</mat-form-field>

							<mat-form-field fxFlex="100%" fxFlex.gt-xs="1 1 50%">
								<mat-label>Phone Number</mat-label>
								<input type="tel" matInput formControlName="phone" placeholder="Phone Number" required>
								<mat-error *ngIf="form.get('phone').hasError('required')">This field is required.</mat-error>
							</mat-form-field>

						</div>

						<div fxLayout="row wrap" fxLayoutAlign="center start">

							<div fxLayout="column" fxFlex="100%" fxFlex.gt-xs="1 1 50%">

								<mat-form-field fxFlex class="w-100-p">
									<mat-label>Street Address</mat-label>
									<input matInput formControlName="address_1" placeholder="Street Address" required>
									<mat-error>This field is required.</mat-error>
								</mat-form-field>

								<mat-form-field fxFlex class="w-100-p">
									<mat-label>Address Line 2</mat-label>
									<input matInput formControlName="address_2" placeholder="Apt, Suite, Floor">
								</mat-form-field>

							</div>

							<div fxLayout="column" fxFlex="100%" fxFlex.gt-xs="1 1 50%">

								<mat-form-field fxFlex class="px-16 w-100-p">
									<mat-label>City</mat-label>
									<input matInput formControlName="city" placeholder="City" required>
									<mat-error>This field is required.</mat-error>
								</mat-form-field>

								<div fxLayout="row wrap" fxFlex="100%">

									<mat-form-field fxFlex fxFlex.gt-xs="50%">
										<mat-label>State</mat-label>
										<input matInput formControlName="state" placeholder="State" required>
										<mat-error>This field is required.</mat-error>
									</mat-form-field>

									<mat-form-field fxFlex fxFlex.gt-xs="50%">
										<mat-label>Zipcode</mat-label>
										<input matInput formControlName="zip" placeholder="Zipcode" required>
										<mat-error>This field is required.</mat-error>
									</mat-form-field>

								</div>

							</div>

						</div>

					</div>

				</section>

				<section class="form-section">
					<div class="form-section-body">

						<div fxLayout="row wrap" fxLayoutAlign="center start">

							<mat-form-field fxFlex fxFlex.gt-sm="1 1 50%" fxFlexOrder="0">
								<mat-label>Date Situation Began</mat-label>
								<input formControlName="date_began" matInput [matDatepicker]="picker" required>
								<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
								<mat-error>Date invalid.</mat-error>
								<mat-datepicker #picker></mat-datepicker>
							</mat-form-field>

							<mat-form-field fxFlex fxFlex.gt-sm="1 1 50%" fxFlexOrder="1" fxFlexOrder.gt-sm="3">
								<mat-label>Company Name (if applicable)</mat-label>
								<input matInput formControlName="company_name" placeholder="Company Name">
							</mat-form-field>

							<mat-form-field fxFlex fxFlexOrder="2">
								<mat-label>Amount of money lost</mat-label>
								<mat-icon matPrefix>attach_money</mat-icon>
								<input matInput formControlName="amount_lost" placeholder="Amount lost">
							</mat-form-field>

						</div>

						<mat-form-field class="w-100-p mb-20">
							<mat-label>Tell us your story</mat-label>
							<textarea matInput formControlName="story" maxlength="500" rows="5" #storyInput cdkTextareaAutosize
								cdkAutosizeMaxRows="15" required></textarea>
							<mat-hint align="end">{{storyInput.value?.length || 0}} / 500</mat-hint>
							<mat-error>This field is required.</mat-error>
						</mat-form-field>

						<div fxLayout="row wrap" fxLayoutAlign="center start">

							<div class="px-16" fxFlex="100%" fxFlex.gt-sm="1 1 50%">
								<div class="mat-title">Supporting Documents</div>
								<p class="hint-text">
									Please send us any additional documentation for your situation. This includes a
									contract, written estimates, emails and pictures. Anything you think would be helpful for our
									investigative team.
								</p>
								<p class="hint-text">
									Supported formats: (.pdf, .doc, .docx, .jpeg, .jpg, .png)<br>
									Max Files: 5, Max Filesize: 5MB
								</p>
								<button mat-raised-button color="warn" type="button" (click)="uploader.click()" [disabled]="form.disabled" #uploadBtn>
									<input name="file" type="file" #uploader (change)="onFilesChanged($event)" accept=".pdf,.doc,.docx,.jpg,.jpeg,.png " hidden multiple />
									<mat-icon>file_upload</mat-icon>
									Upload File
							</button>
							</div>

							<div class="px-16 pt-32 pt-md-0" fxFlex="100%" fxFlex.gt-sm="1 1 50%">
								<span class="hint-text" *ngIf="!files?.length">No files added</span>
								<ul>
									<li *ngFor="let file of files">
										<span id="file-label">
											{{ file.name }}
										</span>
									</li>
								</ul>
							</div>

						</div>

					</div>
				</section>

				<section class="form-section form-section-footer">
					<button mat-raised-button (click)="submit()" [disabled]="form.disabled">{{ submitText }}</button>
				</section>

			</form>

		</mat-card>
	</div>
</div>
