import { Component, OnInit } from '@angular/core';
import { insinvAnimations } from '@insinv/animations';

@Component({
  selector: 'coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss'],
  animations: insinvAnimations
})
export class ComingSoonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
