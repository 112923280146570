<div class="content-container">
	<div id="error-404" fxLayout="column" fxLayoutAlign="center center">

		<mat-card class="insinv-card" [@animate]="{value:'*',params:{duration:'300ms',y:'100px', delay:'10ms'}}">

			<mat-card-content>

				<h1 class="mat-display-4">404</h1>

				<div class="card-divider"></div>

				<p class="grey-800-fg">Sorry, we couldnt find what you were looking for.</p>

			</mat-card-content>

		</mat-card>

	</div>
</div>
