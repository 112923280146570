import { NgModule } from '@angular/core';

import { InsInvSidebarComponent } from './sidebar.component';

@NgModule({
    declarations: [
        InsInvSidebarComponent
    ],
    exports     : [
        InsInvSidebarComponent
    ]
})
export class InsInvSidebarModule
{
}
