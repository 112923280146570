import { Component, OnInit } from '@angular/core';
import { VideoService } from 'app/services/video.service';
import { MatDialog } from '@angular/material/dialog';
import { VideoPlayerDialogComponent } from 'app/components/video-player-dialog/video-player-dialog.component';
import { tap } from 'rxjs/operators';
import { Video } from 'app/models/video.model';

@Component({
	selector: 'app-behind-the-scenes',
	templateUrl: './behind-the-scenes.component.html',
	styleUrls: ['./behind-the-scenes.component.scss']
})
export class BehindTheScenesComponent implements OnInit {

	videos: Video[] = [];

	dialogRef: any;

	constructor(
		private _videoService: VideoService,
		private _matDialog: MatDialog
	) { }

	ngOnInit(): void {
		this._videoService.getPlaylistVideos("PLifENFfiIJdqSbGgS-URKXykq3i4spgUL").pipe(
			tap(response => this.videos = response.data)
		).subscribe();
	}

	watchVideo(video: Video): void {
		this.dialogRef = this._matDialog.open(VideoPlayerDialogComponent, {
			maxWidth: '100%',
			width: '600px',
			panelClass: 'video-player-dialog',
			data: { videoId: video.id }
		});
	}

}
