import { ChangeDetectionStrategy, Component, ContentChild, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
	selector: 'insinv-form-content',
	template: '<ng-content></ng-content>',
})
export class InsinvFormContent { }

@Component({
	selector: 'insinv-form-title',
	template: '<ng-content></ng-content>',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsinvFormTitle { }

@Component({
	selector: 'insinv-form-subtitle',
	template: '<ng-content></ng-content>',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsinvFormSubtitle { }

@Component({
	selector: 'insinv-form-actions',
	template: '<ng-content></ng-content>',
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { 'class': 'insinv-form-actions' }
})
export class InsinvFormActions { }

@Component({
	selector: 'insinv-form',
	templateUrl: './insinv-form.component.html',
	styleUrls: ['./insinv-form.component.scss'],
})
export class InsinvFormComponent
{
	@Input() formGroup: FormGroup;
	@ContentChild(InsinvFormContent)
	formContent: InsinvFormContent;
}
