import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, pipe, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class IpAddressService {

	// Private
	private _ipAddress$: BehaviorSubject<any>;
	private readonly _defaultIp: string = '127.0.0.1';

	constructor(
		private _http: HttpClient
	) {
		this._ipAddress$ = new BehaviorSubject(this._defaultIp);
	}

	get ipAddress(): Observable<any> {
		return this._ipAddress$.getValue();
	}

	fetchClientIp(): any {
		return this._http.get<any>("//api.ipify.org/?format=json").pipe(
			tap(response => {
				if( window.localStorage ) {
					localStorage.setItem('_userIp', response.ip)
				}
				this._ipAddress$.next(response.ip);
			}),
			catchError(error => {
				console.error('Oh no!');

				return of(null);
			}),
		)
	}
}
