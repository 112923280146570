import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'app/services/api.service';

function requireCheckboxesToBeCheckedValidator(): ValidatorFn {
	return function validate(formGroup: FormGroup) {
	  let checked = 0;

	  Object.keys(formGroup.controls).forEach(key => {
		 const control = formGroup.controls[key];

		 if (control.value === true) {
			checked ++;
		 }
	  });

	  if (checked < 1) {
		 return {
			required: true,
		 };
	  }

	  return null;
	};
}

@Component({
	selector: 'mass-tort',
	templateUrl: './mass-tort.component.html',
	styleUrls: ['./mass-tort.component.scss']
})
export class MassTortComponent implements OnInit {

	form: FormGroup;
	sent = false;
	submitting = false;
	files: File[] = [];

	@ViewChild('uploadBtn') uploadBtn: MatButton;

	constructor(
		private _fb: FormBuilder,
		private _apiService: ApiService,
		private _matSnackbar: MatSnackBar
	) { }

	ngOnInit(): void {

		this.form = this._fb.group({
			products: this._fb.group({
				'Paraquat Herbicide': [false],
				"Infant Formula": [false],
				'Johnson and Johnson Baby Powder': [false],
				'Paragard IUD': [false],
				"Bair Hugger Surgical Warming Blanket": [false],
			}),
			name: [null, Validators.required],
			email: [null, [Validators.required, Validators.email]],
			phone: [null, Validators.required],
			address_1: ['', Validators.required],
			address_2: [''],
			city: ['', Validators.required],
			state: ['', Validators.required],
			zipcode: ['', Validators.required],
			message: [null, Validators.maxLength(1200)]
		})
	}

	uploadFile(file: any): Promise<File> {

		return new Promise( (resolve, reject) => {
			const formData = new FormData();
			formData.append('file', file);
			this._apiService.uploadFile(formData).subscribe(
				(tmpPath: string) => {
					this.form.enable();
					file.serverPath = tmpPath;
					resolve(file);
				}, reject);

		});
	}

	onFilesChanged(event): any {
		const files = event.target.files;

		if(!files.length) return;

		if(this.files.length > 5) {
			return alert('Cannot add more than 5 documents');
		}

		if(files.length + this.files.length > 5) {
			return alert('The amount of documents you have selected exceeds the maximum amount of documents you can upload (5)');
		}

		this.uploadBtn.disabled = true;

		[...files].forEach( (file: File) => {
			this.uploadFile(file).then(
				(response: File) => this.files.push(response)
			).finally( () => this.uploadBtn.disabled = false);
		})
	}

	submitForm(): void {
		this.validateProducts();
		if(this.form.invalid) return;
		this.submitting = true;

		const files = this.files.map( (file: any) => {
			return {
				serverPath: file.serverPath,
				lastModified: file.lastModified,
				name: file.name,
				size: file.size,
				type: file.type
			}
		})
		const formData = {
			files,
			...this.form.value
		}

		formData.products = this.productsValue;

		this.form.disable();
		this._apiService.post('/forms/insideinvestigations/mass-tort-contact', formData).subscribe(
			response => {
				this.showMessage(['green', 'insinv-white-fg'], 'Form Saved! Please check your email for confirmation of our receipt of your information.');
				this.submitting = false;
				this.sent = true;
			},
			error => {
				this.form.enable();
				this.submitting = false;
				this.showMessage(['warn'], error.message);
			}
		)
	}

	validateProducts(): void {
		let checked = 0;
		Object.keys(this.productsControl.controls).forEach(key => {
			if (this.productsControl.get(key).value === true) {
				checked++;
			}
		});

		if (checked < 1) {
			this.productsControl.setErrors({
				required: true
			})
		}
	}

	showMessage(classlist = ['primary'], message: string): void {
		this._matSnackbar.open(message, null, {
			panelClass: [...classlist, 'text-center'],
			duration: 5000
		});
	}

	get buttonText(): string {
		return this.submitting
			? 'Sending...'
			: (this.sent ? 'Form Sent!' : 'Submit Form');
	}

	get productsControl(): FormGroup {
		return this.form.get('products') as FormGroup;
	}

	get productsValue(): string[] {
		return Object.entries(this.productsControl.value).reduce((acc, entry) => {
			if (entry[1] === true) acc.push(entry[0]);
			return acc;
		}, []);

	}
}
