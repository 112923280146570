<div id="video-grid-skeleton">
	<div class="rich-shelf-videos">

		<ng-template #skeletonContainer></ng-template>

	</div>
</div>

<ng-template #videoSkeleton>
	<div class="rich-grid-video-skeleton">
		<div class="rich-thumbnail-container">
			<div class="rich-thumbnail skeleton-bg-color"></div>
		</div>
		<div class="details">
			<div class="rich-video-title skeleton-bg-color"></div>
			<div class="rich-video-description skeleton-bg-color"></div>
			<div class="rich-video-description skeleton-bg-color"></div>
		</div>
	</div>
</ng-template>
