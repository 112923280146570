import { InsInvConfig } from '@insinv/types';

/**
 * Default Fuse Configuration
 *
 * You can edit these options to change the default options. All these options also can be
 * changed per component basis. See `app/main/pages/authentication/login/login.component.ts`
 * constructor method to learn more about changing these options per component basis.
 */

export const insinvConfig: InsInvConfig = {
  layout: {
    navbar: {
      folded: false,
      hidden: false,
      position: 'left',
      variant: 'vertical-style-1'
    },
  }
};
