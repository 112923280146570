import { Video as VideoInterface } from "app/models/video.model";

export class Video {
	id: string;
	title: string;
	description: string;
	duration: string;
	thumbnail: string;

	constructor(
		video: VideoInterface
	) {
		this.id = video.id;
		this.title = video.title;
		this.description = video.description;
		this.duration = video.duration;
		this.thumbnail = video.images.normal;
	}
}
