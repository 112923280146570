import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

import { InsInvNavigationComponent } from './navigation.component';
import { InsInvNavVerticalItemComponent } from './vertical/item/item.component';
import { InsInvNavVerticalCollapsableComponent } from './vertical/collapsable/collapsable.component';
import { InsInvNavVerticalGroupComponent } from './vertical/group/group.component';
import { InsInvNavHorizontalItemComponent } from './horizontal/item/item.component';
import { InsInvNavHorizontalCollapsableComponent } from './horizontal/collapsable/collapsable.component';

@NgModule({
    imports     : [
        CommonModule,
        RouterModule,

        MatIconModule,
        MatRippleModule,
    ],
    exports     : [
        InsInvNavigationComponent
    ],
    declarations: [
        InsInvNavigationComponent,
        InsInvNavVerticalGroupComponent,
        InsInvNavVerticalItemComponent,
        InsInvNavVerticalCollapsableComponent,
        InsInvNavHorizontalItemComponent,
        InsInvNavHorizontalCollapsableComponent
    ]
})
export class InsInvNavigationModule
{
}
