import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dales-book',
  templateUrl: './dales-book.component.html',
  styleUrls: ['./dales-book.component.scss']
})
export class DalesBookComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
