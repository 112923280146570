import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LayoutService {

	onFooterVpChange$: Subject<any>;

	constructor() {
		this.onFooterVpChange$ = new Subject();
	}
}
