import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
} from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const { apiKey: token } = environment;
		const clonedRequest = req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });


		return next.handle(clonedRequest);
	}
}
