<div class="content-container">
	<div id="behind-the-scenes">

		<div class="bts-header">

			<header class="page-header left">
				<h1 class="page-title">Behind The Scenes</h1>
			</header>

		</div>

		<div class="bts-body pt-32">

			<video-grid-skeleton *ngIf="!videos.length"></video-grid-skeleton>

			<section class="video-list" *ngIf="videos.length">

				<ng-container *ngFor="let video of videos">

					<div class="video-list-item">

						<div class="image-container" (click)="watchVideo(video)">
							<div class="play-overlay">
								<mat-icon>play_arrow</mat-icon>
							</div>
							<img [src]="video.images.normal" [alt]="video.title" [title]="video.title">
						</div>

						<div class="video-list-item-content">

							<div class="video-content-header">
								<h1 class="video-title">{{ video.title }}</h1>

								<div class="video-description">{{ video.description }}</div>
							</div>

						</div>
					</div>

				</ng-container>

			</section>

		</div>

	</div>
</div>
