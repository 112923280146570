<div class="navbar-top">

	<h4 class="secondary-text text-uppercase">Navigation</h4>

	<div class="buttons">

		<button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
			<mat-icon class="secondary-text">menu</mat-icon>
		</button>

		<button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
			<mat-icon class="secondary-text">arrow_back</mat-icon>
		</button>

	</div>

</div>


<div class="navbar-scroll-container">

	<div class="navbar-content">
		<insinv-navigation class="material2" layout="vertical"></insinv-navigation>
	</div>

</div>
