import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvestigationsComponent } from './investigations/investigations.component';
import { BehindTheScenesComponent } from './behind-the-scenes/behind-the-scenes.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { WatchOnTvComponent } from './watch-on-tv/watch-on-tv.component';
import { Error404Component } from './404/error-404.component';
import { ReactiveFormsModule } from '@angular/forms';

import { InsInvSharedModule } from '@insinv/shared.module';

import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ComponentsModule } from 'app/components/components.module';
import { MassTortComponent } from './mass-tort/mass-tort.component';
import { InsinvFormModule } from 'app/components/insinv-form/insinv-form.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';



@NgModule({
	declarations: [
		BehindTheScenesComponent,
		ContactUsComponent,
		WatchOnTvComponent,

		// Error Pages
		Error404Component,

		ComingSoonComponent,

		PrivacyPolicyComponent,

		MassTortComponent,
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,

		MatInputModule,
		MatCheckboxModule,
		MatButtonModule,
		MatIconModule,
		MatSelectModule,
		MatFormFieldModule,
		MatProgressBarModule,
		MatCardModule,
		MatDatepickerModule,
		MatTableModule,
		MatDividerModule,

		InsInvSharedModule,
		InsinvFormModule,

		ComponentsModule
	],
})
export class PagesModule { }
