import { BrowserModule, Title, Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { InsInvModule } from '@insinv/insinv.module';
import { InsInvSharedModule } from '@insinv/shared.module';
import { InsInvProgressBarModule, InsInvSidebarModule } from '@insinv/components';
import { insinvConfig } from './insinv-config';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LayoutModule } from './layout/layout.module';
import { PagesModule } from './pages/pages.module';

import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { ApiService } from './services/api.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ComponentsModule } from './components/components.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { InViewportModule } from '@thisissoon/angular-inviewport';
import { AuthInterceptor } from './services/auth.interceptor';

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,

		// Material Modules
		MatCardModule,
		MatNativeDateModule,
		MatRippleModule,
		MatSnackBarModule,

		// Other
		InViewportModule,

		// InsInv modules
		InsInvModule.forRoot(insinvConfig),
		InsInvProgressBarModule,
		InsInvSidebarModule,
		InsInvSharedModule,

		ComponentsModule,
		LayoutModule,
		PagesModule
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		ApiService,
		Title,
		Meta
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
