import { Component, OnInit, ViewChild, ComponentFactoryResolver, ViewContainerRef, AfterViewInit } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

@Component({
	selector: 'video-grid-skeleton',
	templateUrl: './video-grid-skeleton.component.html',
	styleUrls: ['./video-grid-skeleton.component.scss']
})
export class VideoGridSkeletonComponent implements AfterViewInit {

	@ViewChild('skeletonContainer', { read: ViewContainerRef }) skeletonContainer: ViewContainerRef;
	@ViewChild('videoSkeleton') videoSkeleton: any;

	constructor(
		private _platform: Platform,
		private _componentFactoryResolver: ComponentFactoryResolver
	) { }

	ngAfterViewInit(): void {

		var instanceCount = 21;

		if(this._platform.ANDROID || this._platform.IOS) {
			instanceCount = 6;
		}

		for( var i = 0; i < instanceCount; i++ ) {
			this.skeletonContainer.createEmbeddedView(this.videoSkeleton);
		}
	}

}
