import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { INSINV_CONFIG } from '@insinv/services/config.service';

@NgModule()
export class InsInvModule
{
    constructor(@Optional() @SkipSelf() parentModule: InsInvModule)
    {
        if ( parentModule )
        {
            throw new Error('InsInvModule is already loaded. Import it in the AppModule only!');
        }
    }

    static forRoot(config): ModuleWithProviders<InsInvModule>
    {
        return {
            ngModule : InsInvModule,
            providers: [
                {
                    provide : INSINV_CONFIG,
                    useValue: config
                }
            ]
        };
    }
}
