import { InsInvNavigation } from '@insinv/types';

export const navigation: InsInvNavigation[] = [
	{
		id: 'investigations',
		title: 'Investigations',
		type: 'item',
		url: '/investigations'
	},
	//   {
	//     id: 'bts',
	//     title: 'Behind The Scenes',
	//     type: 'item',
	//     url: '/behind-the-scenes'
	//   },
	{
		id: 'contact',
		title: 'Contact Us',
		type: 'item',
		url: '/contact-us'
	},
	{
		id: 'watch-tv',
		title: 'Watch On TV',
		type: 'item',
		url: '/watch-on-tv'
	},
	{
		id: 'about',
		title: 'About Dale Cardwell',
		type: 'item',
		url: '/about-dale'
	},
	{
		id: 'trustdale',
		title: 'TrustDALE',
		type: 'item',
		url: 'https://trustdale.com/',
		externalUrl: true
	},
];
