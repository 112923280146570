<div class="nav" [ngClass]="{'horizontal':layout === 'horizontal', 'vertical':layout === 'vertical'}">

	<!-- Vertical Navigation Layout -->
	<ng-container *ngIf="layout === 'vertical'">

		 <ng-container *ngFor="let item of navigation">

			  <insinv-nav-vertical-group *ngIf="item.type=='group'" [item]="item"></insinv-nav-vertical-group>
			  <insinv-nav-vertical-collapsable *ngIf="item.type=='collapsable'"
														[item]="item"></insinv-nav-vertical-collapsable>
			  <insinv-nav-vertical-item *ngIf="item.type=='item'" [item]="item"></insinv-nav-vertical-item>

		 </ng-container>

	</ng-container>
	<!-- / Vertical Navigation Layout -->

	<!-- Horizontal Navigation Layout -->
	<ng-container *ngIf="layout === 'horizontal'">

		 <ng-container *ngFor="let item of navigation">

			  <insinv-nav-horizontal-collapsable *ngIf="item.type=='group'" [item]="item"></insinv-nav-horizontal-collapsable>
			  <insinv-nav-horizontal-collapsable *ngIf="item.type=='collapsable'"
														  [item]="item"></insinv-nav-horizontal-collapsable>
			  <insinv-nav-horizontal-item *ngIf="item.type=='item'" [item]="item"></insinv-nav-horizontal-item>

		 </ng-container>

	</ng-container>
	<!-- / Horizontal Navigation Layout -->

</div>
