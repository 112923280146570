import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { InsInvConfigService } from '@insinv/services/config.service';
import { InsInvNavigationService } from '@insinv/components/navigation/navigation.service';
import { InsInvSidebarService } from '@insinv/components/sidebar/sidebar.service';

@Component({
    selector     : 'navbar-horizontal',
    templateUrl  : './nav-horizontal.component.html',
    styleUrls    : ['./nav-horizontal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarHorizontalComponent implements OnInit, OnDestroy
{
    fuseConfig: any;
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {InsInvConfigService} _insinvConfigService
     * @param {InsInvNavigationService} _insinvNavigationService
     * @param {InsInvSidebarService} _insinvSidebarService
     */
    constructor(
        private _insinvConfigService: InsInvConfigService,
        private _insinvNavigationService: InsInvNavigationService,
        private _insinvSidebarService: InsInvSidebarService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Get current navigation
        this._insinvNavigationService.onNavigationChanged
            .pipe(
                filter(value => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation = this._insinvNavigationService.getCurrentNavigation();
            });

        // Subscribe to the config changes
        this._insinvConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
