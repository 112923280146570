<div class="content-container">
	<div id="privacy-policy">

		<header class="page-header">
			<h1 class="page-title">Privacy Policy</h1>
		</header>

		<section>

			<p>InsideInvestigations.com respects the privacy of visitors to our website. This Privacy Policy governs the use and collection of information from you by InsideInvestigations.com through your use of this website. This Privacy Policy does not apply to any information you may provide to us through other means; for example, via mail, or via telephone.</p>

			<p>Please read this Privacy Policy carefully so that you understand our online privacy practices. By visiting this website, you agree that your visit, and any dispute over our online privacy practices, is governed by this Privacy Policy. Questions regarding privacy issues should be directed to Dale Cardwell at <a href="mailto:info@trustdale.com">info@trustdale.com.</a></p>

		</section>

		<section>

			<header>TYPES OF INFORMATION WE COLLECT</header>

			<p>The information we gather falls into two categories: (1) information (for example, your name, your phone number or your email address) that you voluntarily supply, for example, when you use the site to ask us to help you with a consumer related probelm (2) information gathered on usage patterns and preferences as visitors navigate through our website.</p>

			<p>Email Newsletters. We may offer you the opportunity to subscribe our TrustDale emails and updates on topics that we believe are of interest to you. If you have opted to receive these emails, you may always unsubscribe later if you decide not to receive further emailings from us. Each email will include instructions on how to unsubscribe from future issues by using either a designated reply email address or hyperlink.</p>

			<p>Cookies. To help make our sites more responsive to the needs of our visitors, we keep track of the pages visited by our users by placing a “cookie,” a small entry in text file, on your hard drive. The cookie doesn't actually identify you, just the computer or mobile device that you use to access our website. Unless you voluntarily identify yourself (through email registration, for example), we won't know who you are, even if we assign a cookie to your computer or mobile device. The only personal information a cookie can contain is information you supply. A cookie can't read unrelated data off your hard drive. Our Service Providers that provide products or services through our website may also assign their own cookies to your browser, a process that we don't control. We use cookies to help us tailor our website to your needs, to deliver a better, more personalized service. For example, we may use cookies to allow you to search for those businesses closest to your home. In addition, we may use cookies to track the pages on our website visited by our users. We can build a better website if we know which pages our users are visiting and how often. You can manage your browser's cookie setting through the “options” menu on most commercially available web browsers, including options to set your browser to notify you before accepting a cookie or to disable cookies entirely. Of course, you can set your browser not to accept cookies, but if you do, you may not be able to take advantage of the personalized features enjoyed by other visitors to our website.</p>

			<p>Web Beacons. Our web pages may contain electronic images (called “single-pixel GIFs” or “web beacons”) that allow us and our Affiliates, Service Providers, and, where necessary, our advertisers to count users who have visited particular pages of this site or sites owned or operated by third parties or to access certain cookies. We may use these tools and other technologies to recognize which pages users visit, and the links on which they click. These features may also be included in our email newsletters so that we can learn which messages have been opened and acted upon. We may also be able to verify your online purchases from us or from third-party sites that have agreed to share such information with us. We may use “clickstream” data collected using web beacons and cookies to help us tailor the content of promotional messages, including email messages, to the perceived interests of our users.</p>

			<p>Browser Level Information and IP Addresses. Our web servers automatically collect limited information about your computer configuration or your mobile device when you visit our website, including the type of browser software you use, the operating system you're running, the resolution of your computer monitor, the Web website that referred you, the type of device you are using, and your IP address. (Your IP address is a numerical address that is used by computers connected to the Internet to identify your computer so that data such as the web pages you want to view can be transmitted to you.) We also use IP address information for systems administration and troubleshooting purposes. Your IP address does not tell us who you are. We use this information to deliver our web pages to you upon request, to tailor our website to the interests of our users, and to measure traffic within our website.</p>

		</section>

		<section>

			<header>DISCLOSURE OF INFORMATION</header>

			<p>Why we want to know. We collect information in order to make this website more useful and relevant to you. Except as expressly set forth in this Privacy Policy (for example, as described under “Other Disclosures” below, we will not sell or rent to any third party any personally identifying information that we collect from you through your use of the this website.</p>

			<p>Statistical Information. Much of the information we collect is in the form of aggregated statistics, such as the traffic that visits various pages within our sites, and the habits and preferences of our audience. Such aggregated information does not include any information that would identify you personally. We may use such aggregated information and disclose it to third parties as we see fit.</p>

			<p>Sites to Which We Link. Our website includes links to other Web sites, and provides access to products and services offered by third parties, whose privacy policies we don't control. When you access another site or purchase products or services or conduct other transactions through our sites, use of any information you provide is governed by the privacy policy of the operator of the site you're visiting or the provider of such products or services.</p>

			<p>Other Disclosures. We reserve the right to release information about visitors to this website when release is necessary to comply with law, to enforce this Privacy Policy, or to protect the rights, property or safety of visitors to our website, the public, our customers, or our company and its employees, agents, partners and Affiliates.</p>

		</section>

		<section>

			<header>CHILDREN'S PRIVACY</header>

			<p>Children should always get permission from their parents before sending any information about themselves (such as their names, email addresses, and phone numbers) over the Internet, to us or to anyone else. We won't knowingly allow anyone under 18 to provide business information to our website, or to provide any other personally identifying information. If you're under 18, please do not provide us with any personally identifying information about yourself (such as your name, your email address or your phone number). If we become aware that we have collected any personally identifying information from a user under the age of 18, we will remove such information from our records as soon as possible.</p>

		</section>

		<section>

			<header>OPTING IN/OPTING OUT</header>

			<p>If you have subscribed to our email newsletters, you will always have the opportunity to unsubscribe from future mailings (for example, by clicking on an unsubscribe link in an email newsletter or by modifying your account settings on our website).</p>

			<p>You agree, however, that, subject to applicable law, we may use your information to contact you for customer service, to inform you of important changes or additions to our website or the services offered over our website and to send you administrative notices or any communications relevant to your use of our website.</p>

			<p>If you have submitted your information on a page provided in conjunction with one of our Service Providers, the information you submit may be jointly maintained by us and the Service Provider.</p>

		</section>

		<section>

			<header>STORAGE OF INFORMATION</header>

			<p>All information gathered on our website is stored within a database operated by us or by a Service Provider on our behalf. Prior to our receipt of this information, however, we cannot guarantee that information you supply won't be intercepted while being transmitted to us over the Internet.</p>

		</section>

		<section>

			<header>CHANGES TO THIS PRIVACY POLICY</header>

			<p>We may change the terms of this Privacy Policy or introduce new terms and conditions from time to time, in which case we will post an updated version of this Privacy Policy on this website and will update the date above to reflect the date the changes take effect. By continuing to use this website after we post any such changes, you accept this Privacy Policy, as modified.</p>

		</section>
	</div>
</div>
